import React, { useEffect } from 'react';
import TotalArea from './TotalArea';
import TotalEl from './TotalEl';
import TotalWorkRecord from './TotalWorkRecord';
import WorkCategory from './WorkCategory';
import WorkLogsMonthlyCount from './MonthWorkRecode';
import UserMonthWorkRecord from './UserMonthWorkRecord';
import WorkerLog from './WorkerLog';

const Dashboard = () => {
  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 최상단으로 이동
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="dashboard">
      <div className="flex items-center flex-col mt-6">
        <h2 className="text-2xl font-bold text-center mb-4 mt-6">📈 데이터 분석자료 📊</h2>
      </div>
      <div className="flex flex-wrap justify-center items-center lg:flex-nowrap lg:justify-around px-4 gap-4">
        <TotalArea />
        <TotalEl />
        <UserMonthWorkRecord />
        <TotalWorkRecord />
      </div>
      <div className="mt-20 flex flex-wrap justify-center items-center lg:flex-nowrap lg:justify-around px-4 gap-4">
        <WorkCategory />
        <WorkLogsMonthlyCount />
        <WorkerLog />
      </div>
    </div>
  );
};

export default Dashboard;
