// InventoryList.js
import React from 'react';
import InventoryItem from './InventoryItem';

const InventoryList = ({ inventoryItems, handleInputChange, handleSaveItem, handleEditItem, handleShowDeleteModal, handleUsageHistory }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">부품명</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">현재 재고</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">가장 최근에 입력한 메모</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">작업</th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {inventoryItems.map((item) => (
            <InventoryItem
              key={item.id}
              item={item}
              handleInputChange={handleInputChange}
              handleSaveItem={handleSaveItem}
              handleEditItem={handleEditItem}
              handleShowDeleteModal={handleShowDeleteModal}
              handleUsageHistory={handleUsageHistory} // 함수를 InventoryItem으로 전달
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InventoryList;
