// ImageModal.js
import React from 'react';

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center" onClick={onClose}>
      <div className="modal-content p-4 bg-white rounded-lg max-w-3xl max-h-full overflow-auto" onClick={(e) => e.stopPropagation()}>
        {/* 이미지 중앙 정렬 */}
        <div className="flex justify-center">
          <img src={imageUrl} alt="Full Size" style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'contain' }} />
        </div>
        {/* '닫기' 버튼 오른쪽 및 회색으로 설정 */}
        <div className="flex justify-end mt-4">
          <button onClick={onClose} className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600">닫기</button>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
