import React from 'react';

const DateInput = ({ date, onDateChange }) => {
  return (
    <div className="mb-4">
      <label htmlFor="date" className="block text-sm font-medium text-gray-700">날짜 선택(필수)</label>
      <input
        type="date"
        name="date"
        id="date"
        value={date}
        onChange={onDateChange}
        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
        required
      />
    </div>
  );
};

export default DateInput;
