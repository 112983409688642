// src/components/ui/modal/PlaceDelModal.js
import React from 'react';

const PlaceDelModal = ({ isOpen, onClose, onConfirmDelete, dataToDelete }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="relative bg-white rounded-lg shadow-xl p-6 w-full max-w-md m-4">
        <h3 className="text-lg font-medium leading-6 text-gray-900">현장 데이터 삭제 확인</h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500">정말로 현장 데이터를 삭제하시겠습니까?</p>
          <p className="text-sm font-bold text-gray-800">현장명 : {dataToDelete?.place}</p>
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={() => onConfirmDelete(dataToDelete.id)}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mr-2"
          >
            삭제
          </button>
          <button
            onClick={onClose}
            className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mr-2"
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default PlaceDelModal;
