import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase';

const TotalWorkRecord = () => {
  const [totalRecords, setTotalRecords] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchTotalRecords = async () => {
      const querySnapshot = await getDocs(collection(db, "newUnifiedData"));
      setTotalRecords(querySnapshot.size); // Firestore에서 가져온 총 개수를 totalRecords 상태에 저장
    };

    fetchTotalRecords();
  }, []);

  useEffect(() => {
    // 숫자 카운팅 애니메이션을 더 빠르게 진행
    const increment = Math.ceil(totalRecords / 1000); // 한 번에 증가할 양을 조정
    if (count < totalRecords) {
      const timeout = setTimeout(() => setCount(count + increment > totalRecords ? totalRecords : count + increment), 1); // 지연 시간을 줄임
      return () => clearTimeout(timeout);
    }
  }, [count, totalRecords]);

  return (
    <div className="flex justify-center items-center mt-10">
      <div className="bg-gradient-to-br from-indigo-400 via-purple-500 to-pink-500 text-white text-center p-6 rounded-lg shadow-xl" style={{ width: '300px', height: '300px' }}>
        <h2 className="text-xl font-bold mb-2">총 작업일지 기록 수</h2>
        <div className="text-6xl font-bold mb-2">
          {count}
        </div>
        <p className="text-lg">
          이렇게나 많은 현장으로 출동하여, 사람들의 안전을 책임지고 있습니다. 우리는 도시의 운영을 책임지는 막중하고 위대한 업무를 하고 있습니다.
        </p>
      </div>
    </div>
  );
};

export default TotalWorkRecord;
