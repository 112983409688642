import React from 'react';

const RecordArrivalTime = ({ arrivalTime, onTimeChange }) => {
  return (
    <div className="w-1/2 px-2">
      <label htmlFor="arrival-time" className="block text-sm font-medium text-gray-700">
        도착 시간
      </label>
      <input
        type="time"
        id="arrival-time"
        name="arrivalTime"
        value={arrivalTime}
        onChange={onTimeChange}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    </div>
  );
};

export default RecordArrivalTime;
