import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase';
import useAuth from '../../hooks/useAuth'; // useAuth 훅 임포트

const PersonWorkData = () => {
  const [workLogCount, setWorkLogCount] = useState(0);
  const currentUser = useAuth(); // 현재 로그인된 사용자 정보 가져오기

  useEffect(() => {
    const fetchWorkLogs = async () => {
      // currentUser가 유효하고, currentUser.name이 존재할 때만 쿼리 실행
      if (currentUser && currentUser.name) {
        // newUnifiedData 컬렉션에서 현재 로그인된 사용자의 이름과 일치하는 문서를 쿼리
        const workLogsQuery = query(collection(db, "newUnifiedData"), where("creatorName", "==", currentUser.name));
        try {
          const querySnapshot = await getDocs(workLogsQuery);
          setWorkLogCount(querySnapshot.docs.length); // 일치하는 문서의 개수를 상태에 저장
        } catch (error) {
          console.error("Error fetching work logs: ", error);
          setWorkLogCount(0); // 에러 발생 시 카운트를 0으로 설정
        }
      }
    };

    fetchWorkLogs();
  }, [currentUser]); // currentUser 객체의 변화를 감지하여 useEffect를 다시 실행

  // 로그인 상태가 아닐 경우의 처리
  if (!currentUser) {
    return <div>로그인이 필요합니다.</div>;
  }

  return (
    <div className="mt-4">
      <h2>✍🏻 지금까지 {workLogCount} 건의 작업일지를 작성하셨습니다.</h2>
    </div>
  );
};

export default PersonWorkData;

