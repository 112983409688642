import React from 'react';

const RecordCategory = ({ category, onCategoryChange }) => {
  const categories = [
    { label: '점검', value: 'inspection', color: 'bg-green-500' },
    { label: '고장', value: 'change', color: 'bg-yellow-500' },
    { label: '승객갇힘', value: 'emergency', color: 'bg-red-500' },
    { label: '수리공사', value: 'repair', color: 'bg-blue-500' },
    { label: '기타', value: 'etc', color: 'bg-gray-500' }
  ];

  const handleChange = (value) => {
    onCategoryChange({
      target: {
        name: 'category',
        value: value,
      },
    });
  };

  return (
    <div>
      <div className="text-red-600 text-center mb-4 text-sm">
        작업유형을 선택하세요.(반드시 1개 선택)
      </div>
      <div className="flex flex-wrap justify-center gap-2">
        {categories.map((item) => (
          <button
            key={item.value}
            className={`px-3 py-2 text-lg font-semibold rounded-md transition-colors duration-300
              ${category === item.value
                ? `${item.color} text-white`
                : 'bg-white text-gray-600 border border-gray-300'}
              flex-grow`}
            onClick={() => handleChange(item.value)}
            type="button"
          >
            {item.label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default RecordCategory;
