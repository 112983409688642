// src/components/workplace/PlaceForm.js
import React from 'react';

const PlaceForm = ({ data, onDelete, onEdit }) => {
  return (
    <div className="bg-white shadow overflow-hidden rounded-lg p-4 flex flex-col justify-between">
      <div>
        <div className="mb-2">
          <span className="font-medium text-gray-900">현장명: </span>
          {data.place}
        </div>
        <div className="mb-2">
          <span className="font-medium text-gray-900">주소: </span>
          {data.address}
        </div>
        <div className="mb-2">
          <span className="font-medium text-gray-900">호기: </span>
          {Array.isArray(data.unit) ? data.unit.join(', ') : data.unit}
        </div>
      </div>
      <div className="flex justify-end items-end mt-4">
        <button
          onClick={() => onDelete(data)}
          className="text-red-500 hover:text-red-700 font-bold py-2 ml-4"
        >
          삭제
        </button>
        <button
          onClick={() => onEdit(data)}
          className="text-blue-500 hover:text-blue-700 font-bold py-2 ml-4"
        >
          수정
        </button>
      </div>
    </div>
  );
};

export default PlaceForm;
