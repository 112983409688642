// src/components/ui/Modal.js 또는 해당 경로에 맞게 조정하세요.
import React from 'react';

const IsApprovedModal = ({ isOpen, content }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-5 rounded-lg">
        {content} {/* content prop을 직접 렌더링합니다. */}
        {/* <div className="flex justify-end mt-4">
          <button onClick={onClose} className="py-2 px-4 bg-gray-500 hover:bg-gray-700 text-white rounded">
            닫기
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default IsApprovedModal;
