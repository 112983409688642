// src/components/ui/Modal.js
import React from 'react';

const Modal = ({ isOpen, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg shadow-xl">
        {children}
        {/* <div className="text-right mt-4">
          <button 
            onClick={onClose} 
            className="py-2 px-4 bg-gray-500 hover:bg-gray-700 text-white rounded"
          >
            닫기
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Modal;
