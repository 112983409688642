// src/components/header/AuthButtons.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../../Firebase';
import { onAuthStateChanged, signOut } from 'firebase/auth';

const AuthButtons = () => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return unsubscribe; // 구독 해제
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/'); // 로그아웃 후 리디렉트될 경로
    } catch (error) {
      console.error('로그아웃 실패', error);
    }
  };

  if (user) {
    return (
      <div className="flex justify-end">
        {/* 버튼의 padding을 줄이고 margin-right 값을 줄여 공간을 좁힘 */}
        <button onClick={handleLogout} className="text-sm px-2 py-1 border border-transparent rounded hover:border-blue-500 hover:text-blue-500 transition duration-300">로그아웃</button>
        <Link to="/mypage" className="text-sm px-2 py-1 ml-2 border border-transparent rounded hover:border-blue-500 hover:text-blue-500 transition duration-300">마이페이지</Link>
      </div>
    );
  } else {
    return (
      <div className="flex justify-end">
        {/* 로그인 버튼 */}
        <Link to="/login" className="text-sm px-2 py-1 mr-2 border border-transparent rounded hover:border-blue-500 hover:text-blue-500 transition duration-300">로그인</Link>
        {/* 회원가입 버튼 */}
        <Link to="/signup" className="text-sm px-2 py-1 border border-transparent rounded hover:border-blue-500 hover:text-blue-500 transition duration-300">회원가입</Link>
      </div>
    );
  }
};

export default AuthButtons;
