import React, { useEffect, useState } from 'react';
import { db } from '../../Firebase';
import { collection, getDocs } from 'firebase/firestore';

const TotalArea = () => {
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "customerData"));
      setTotal(querySnapshot.size); // Firestore에서 가져온 총 개수를 total 상태에 저장
    };

    fetchData();
  }, []);

  useEffect(() => {
    // 숫자 카운팅 애니메이션
    if (count < total) {
      const timeout = setTimeout(() => setCount(count + 1), 3);
      return () => clearTimeout(timeout);
    }
  }, [count, total]);

  return (
    <div className="flex justify-center mt-10">
      <div className="bg-gradient-to-br from-teal-400 to-blue-600 text-white text-center p-6 rounded-lg shadow-xl" style={{ width: '300px', height: '300px' }}>
        <h2 className="text-xl font-bold mb-2">우리가 관리하는 현장 수</h2>
        <div className="text-6xl font-bold mb-2">
          {count}
        </div>
        <p className="text-lg">
          우리가 관리하고 있는 장소의 개수입니다. 이렇게 많은 장소의 엘리베이터를 우리가 관리하고 사람들의 편의성을 높이는데 아주 큰 기여를 하고 있습니다.
        </p>
      </div>
    </div>
  );
};

export default TotalArea;
