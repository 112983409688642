import React, { useState } from 'react';
import EmployeeStatus from './EmployeeStatus';
import UserApprovalList from './UserApprovalList';
// import { db } from '../../Firebase'; // 필요한 경우 사용

const AdminAccount = () => {
  // activeTab 상태를 추가하여 탭 관리
  const [activeTab, setActiveTab] = useState('status');

  return (
    <div>
      <div className="flex justify-center mb-4">
        <button
          className={`px-4 py-2 ${activeTab === 'status' ? 'bg-gray-800 text-white' : 'bg-gray-300'}`}
          onClick={() => setActiveTab('status')}
        >
          현재 직원 현황
        </button>
        <button
          className={`px-4 py-2 ${activeTab === 'approval' ? 'bg-gray-800 text-white' : 'bg-gray-300'}`}
          onClick={() => setActiveTab('approval')}
        >
          계정 승인
        </button>
      </div>

      {activeTab === 'status' && <EmployeeStatus />}
      {activeTab === 'approval' && <UserApprovalList />}
    </div>
  );
};

export default AdminAccount;
