import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../Firebase';
import UserInfoEditForm from '../components/userInfo/UserInfoEditForm';
import loadingGif from '../assets/loading.gif';

const MyPage = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 최상단으로 이동
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchUserInfo = async (currentUser) => {
      try {
        const userDocRef = doc(db, "users", currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          // currentUser.uid를 포함하여 userInfo 상태를 설정합니다.
          setUserInfo({ uid: currentUser.uid, ...userDocSnap.data() });
        } else {
          console.error('No user data available');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        fetchUserInfo(currentUser);
      } else {
        navigate('/login');
      }
    });

    return unsubscribe; // Clean up the subscription
  }, [navigate]);

  if (!userInfo) {
    return (
      <div className="flex justify-center items-center h-screen">
        <img src={loadingGif} alt="로딩 중..." className="w-20 h-20" /> {/* 예시로 5rem(20) 크기 지정 */}
      </div>
    );
  }

  return (
    <div className="p-4">
      <h1 className="text-xl font-bold">마이페이지</h1>
      <section className="my-4">
        <h2
          className="text-lg font-semibold cursor-pointer"
          onClick={() => setShowEditForm(!showEditForm)}
        >
          기본 정보 변경 {showEditForm ? '↑(접기)' : '↓(펼치기)'}
        </h2>
        {showEditForm && <UserInfoEditForm userInfo={userInfo} />}
      </section>
    </div>
  );
};

export default MyPage;
