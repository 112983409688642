import React from 'react';

const RecordAction = ({ resultOfAction, onActionChange }) => {
  return (
    <div className="relative">
      <label htmlFor="action-select" className="block text-sm font-medium text-gray-700">조치결과:</label>
      <div className="inline-block w-full relative">
        <select
          id="action-select"
          name="resultOfAction"
          value={resultOfAction}
          onChange={onActionChange}
          className="appearance-none w-full p-2 border border-gray-300 rounded-md shadow-sm text-gray-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pl-3 pr-10 py-2"
        >
        <option value="">조치결과를 선택하세요</option>
        <option value="정상 운전">정상 운전</option>
        <option value="임시 운전">임시 운전</option>
        <option value="임시 중지">임시 중지</option>
        <option value="사용 금지">사용 금지</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <svg className="fill-current h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
  );
};

export default RecordAction;
