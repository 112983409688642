// src/pages/LoginPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../Firebase';
import { doc, getDoc } from 'firebase/firestore';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 최상단으로 이동
    window.scrollTo(0, 0);
  }, []);

  // 이메일 유효성 검사 함수
  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // 이메일 입력 핸들러
  const handleEmailChange = (event) => {
    const emailInput = event.target.value;
    setEmail(emailInput);
    setIsEmailValid(validateEmail(emailInput));
  };

  // 패스워드 입력 핸들러
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // 로그인 처리 함수
  const handleLogin = async (event) => {
    event.preventDefault();
    if (!isEmailValid) {
      setError('유효한 이메일 주소를 입력하세요.');
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Firestore에서 사용자의 승인 상태 확인
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists() && userDoc.data().isApproved) {
        navigate('/'); // 사용자가 승인되었으므로 리디렉션
      } else {
        // 사용자가 승인되지 않았을 경우의 처리
        setError('계정이 승인되지 않았습니다. 관리자의 승인을 기다려주세요.');
        return; // 이 에러는 더 이상 처리하지 않아도 되므로, 여기서 함수 실행을 종료합니다.
      }
    } catch (error) {
      // Firebase Authentication 에러 처리
      switch (error.code) {
        case 'auth/invalid-email':
          setError('유효하지 않은 이메일 형식입니다.');
          break;
        case 'auth/user-not-found':
          setError('등록되지 않은 이메일입니다.');
          break;
        case 'auth/wrong-password':
          setError('잘못된 비밀번호입니다.');
          break;
        default:
          setError('로그인에 실패했습니다.'); // 여기서는 일반적인 로그인 실패 메시지를 설정합니다.
      }
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-800">
      <div className="p-6 bg-white rounded-lg shadow-xl max-w-md w-full mx-4 mb-60">
        <h2 className="text-2xl font-bold mb-10 text-center">로그인</h2>

        <form className="space-y-6" onSubmit={handleLogin}>
          <div>
            <label htmlFor="email" className="text-sm font-medium text-gray-700 block mb-2">이메일</label>
            <input
              type="email" 
              id="email" 
              className="w-full p-2 border border-gray-300 rounded-md" 
              value={email}
              onChange={handleEmailChange}
            />
          </div>

          <div>
            <label htmlFor="password" className="text-sm font-medium text-gray-700 block mb-2">패스워드</label>
            <input 
              type="password" 
              id="password" 
              className="w-full p-2 border border-gray-300 rounded-md"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>

          <button 
            type="submit" 
            className={`w-full p-2 rounded-md text-white ${
              isEmailValid 
                ? 'bg-blue-500 hover:bg-blue-600' 
                : 'bg-gray-500 cursor-not-allowed'
            }`}
            disabled={!isEmailValid}
          >
            로그인
          </button>

          {error && <p className="text-red-500 text-sm">{error}</p>}

          <div className="flex justify-between text-sm text-blue-500">
            <Link to="/find-email">아이디 찾기</Link>
            <Link to="/reset-password">비밀번호 찾기</Link>
            <Link to="/signup">회원가입</Link> {/* 수정된 부분 */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginPage;