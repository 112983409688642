import React from 'react';

const RecordSpecialNotes = ({ specialNotes, onNotesChange }) => {
  return (
    <div>
      <label htmlFor="special-notes" className="block text-sm font-medium text-gray-700">
        세부 내역
      </label>
      <textarea
        id="special-notes"
        name="specialNotes"
        value={specialNotes}
        onChange={onNotesChange}
        rows="2"
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        placeholder="세부 내역을 입력하세요."
      ></textarea>
    </div>
  );
};

export default RecordSpecialNotes;
