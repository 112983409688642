import React from 'react';

const RecordMeasures = ({ measures, onMeasuresChange }) => {
  return (
    <div className="relative">
      <label htmlFor="measures-select" className="block text-sm font-medium text-gray-700">조치사항:</label>
      <div className="inline-block w-full relative">
        <select
          id="measures-select"
          name="measures"
          value={measures}
          onChange={onMeasuresChange}
          className="appearance-none w-full p-2 border border-gray-300 rounded-md shadow-sm text-gray-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pl-3 pr-10 py-2"
        >
        <option value="">조치사항을 선택하세요</option>
        <option value="관찰">관찰</option>
        <option value="청소">청소</option>
        <option value="조정">조정</option>
        <option value="교체">교체</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <svg className="fill-current h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
  );
};

export default RecordMeasures;
