import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../../Firebase'; // 실제 경로에 맞게 조정하세요.
import { collection, getDocs } from 'firebase/firestore';

const RecordInven = ({ onPartsSelect }) => {
  const [parts, setParts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedParts, setSelectedParts] = useState([]);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const fetchParts = async () => {
      const snapshot = await getDocs(collection(db, "inventoryItems"));
      const partsData = [];
      snapshot.forEach(doc => {
        partsData.push({ name: doc.data().name, stock: doc.data().stock });
      });
      setParts(partsData);
    };

    fetchParts();
  }, []);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (part) => {
    const partExists = selectedParts.find(p => p.name === part.name);
    if (!partExists) {
      const newList = [...selectedParts, { ...part, quantity: 1 }];
      setSelectedParts(newList);
      onPartsSelect(newList);
    }
    setSearchTerm('');
    setShowSuggestions(false);
  };

  const handleQuantityChange = (partName, quantity) => {
    const updatedParts = selectedParts.map(part => {
      if (part.name === partName) {
        // 변경하려는 수량이 재고보다 크지 않도록 합니다.
        const updatedQuantity = Math.min(quantity, part.stock);
        return { ...part, quantity: updatedQuantity };
      }
      return part;
    });

    setSelectedParts(updatedParts); // 업데이트된 부품 배열로 상태 업데이트
    onPartsSelect(updatedParts); // 변경된 부품 배열을 상위 컴포넌트로 전달
  };

  const adjustQuantity = (partName, adjustment) => {
    const updatedParts = selectedParts.map(part => {
      if (part.name === partName) {
        // 재고를 숫자 타입으로 변환합니다.
        const stockNumber = Number(part.stock);
        // 새로운 수량 계산 시 재고 값을 초과하지 않도록 조정
        let newQuantity = part.quantity + adjustment;
        newQuantity = Math.max(newQuantity, 1); // 수량은 최소 1 이상이어야 합니다.
        newQuantity = Math.min(newQuantity, stockNumber); // 수량은 재고를 초과할 수 없습니다.
        return { ...part, quantity: newQuantity };
      }
      return part;
    });

    setSelectedParts(updatedParts);
    onPartsSelect(updatedParts); // 이 부분이 중요합니다.
  };

  const handleRemovePart = (partName) => {
    const newList = selectedParts.filter(part => part.name !== partName);
    setSelectedParts(newList);
    onPartsSelect(newList);
  };

  return (
    <div ref={wrapperRef} className="relative w-full">
      <label htmlFor="part-search" className="block text-sm font-medium text-gray-700">부품 검색(테스트 중)</label>
      <div className="flex items-center border border-gray-300 rounded-md">
        <input
          id="part-search"
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={() => setShowSuggestions(true)}
          className="w-full p-2 rounded-md"
          placeholder="부품명을 검색하세요."
          autoComplete="off"
        />
      </div>
      {showSuggestions && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto">
          {parts.filter(part => part.name.toLowerCase().includes(searchTerm.toLowerCase())).map((part, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(part)}
              className="p-2 hover:bg-gray-100 cursor-pointer"
            >
              {part.name}
            </li>
          ))}
        </ul>
      )}
      <div className="mt-2">
        {selectedParts.map((part, index) => (
          // 부품 수량 조절 버튼에 대한 코드 업데이트
          <div key={index} className="flex items-center justify-between bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {part.name}
            <div className="flex items-center">
              {/* type="button"을 추가하여 폼 제출을 트리거하지 않도록 함 */}
              <button type="button" onClick={() => adjustQuantity(part.name, -1)} className="px-2">-</button>
              <input
                type="text"
                value={part.quantity}
                onChange={(e) => handleQuantityChange(part.name, parseInt(e.target.value || 0))}
                className="w-16 text-center rounded-md"
                min="1"
              />
              {/* type="button"을 추가하여 폼 제출을 트리거하지 않도록 함 */}
              <button type="button" onClick={() => adjustQuantity(part.name, 1)} className="px-2">+</button>
            </div>
            <button type="button" onClick={() => handleRemovePart(part.name)} className="ml-2 text-gray-500 hover:text-gray-700">
              &times;
            </button>
          </div>

        ))}
      </div>
    </div>
  );
};

export default RecordInven;
