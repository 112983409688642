import React from 'react';

// isLoading, onLoadMore 외에 displayCount와 totalLogsCount를 추가로 받습니다.
const LoadMoreButton = ({ isLoading, onLoadMore/*, displayCount, totalLogsCount*/}) => {
  return (
    <div className="flex justify-center mt-4">
      <button 
        onClick={onLoadMore} 
        disabled={isLoading}
        className="border-2 border-black text-black bg-white hover:bg-gray-100 font-bold py-2 px-4 rounded"
      >
        {isLoading ? '작업일지 불러오는 중...' : `작업일지 더보기`/* `작업일지 더보기 (${displayCount}/${totalLogsCount})` */}
      </button>
    </div>
  );
};

export default LoadMoreButton;
