import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase';

ChartJS.register(ArcElement, Tooltip, Legend);

const WorkCategory = () => {
  const [categoriesCount, setCategoriesCount] = useState({
    inspection: 0, // 점검
    change: 0, // 고장
    emergency: 0, // 승객갇힘
    repair: 0, // 수리공사
  });

  useEffect(() => {
    const fetchCategoryData = async () => {
      const querySnapshot = await getDocs(collection(db, "newUnifiedData"));
      const categoryCounts = { inspection: 0, change: 0, emergency: 0, repair: 0 };

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.category in categoryCounts) {
          categoryCounts[data.category]++;
        }
      });

      setCategoriesCount(categoryCounts);
    };

    fetchCategoryData();
  }, []);

  // 동적으로 레이블을 생성하여 각 카테고리의 개수를 포함시킵니다.
  const labels = [
    `🔍 점검 ${categoriesCount.inspection}개`,
    `💥 고장 ${categoriesCount.change}개`,
    `🚨 승객갇힘 ${categoriesCount.emergency}개`,
    `🛠 수리공사 ${categoriesCount.repair}개`,
  ];

  const data = {
    labels,
    datasets: [
      {
        label: '작업 카테고리별 개수',
        data: [
          categoriesCount.inspection,
          categoriesCount.change,
          categoriesCount.emergency,
          categoriesCount.repair,
        ],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(75, 192, 192, 0.2)',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false, // 비율 유지를 비활성화하여 크기를 직접 제어
    aspectRatio: 1.4, // 그래프의 크기 조정
    plugins: {
      legend: {
        position: 'top',
        labels: {
          generateLabels: (chart) => {
            // 기본 레이블 생성 로직을 사용하여 레이블을 생성하고, 개수 정보를 추가합니다.
            const labels = chart.data.labels.map((label, index) => {
              return {
                text: label, // 여기서 label은 위에서 동적으로 생성한 텍스트를 포함합니다.
                fillStyle: chart.data.datasets[0].backgroundColor[index],
              };
            });
            return labels;
          }
        }
      },
      title: {
        display: true,
        text: '작업 카테고리별 개수',
      },
    },
  };

  return (
      <div className="w-72 h-72 flex flex-col items-center justify-center mt-10">
        <h2 className="text-2xl font-bold mb-4 text-center">🔍🛠 작업분류 데이터 🚨💥</h2>
        <Pie data={data} options={options} />
      </div>
  );
};

export default WorkCategory;
