import { useEffect, useState } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Firebase'; // Firestore 인스턴스를 임포트해야 합니다.

const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // 사용자가 로그인한 경우, Firestore에서 사용자 정보를 가져옵니다.
          const userRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(userRef);

          if (docSnap.exists()) {
            // Firestore 문서에서 사용자 정보를 가져옵니다.
            const userData = docSnap.data();
            setCurrentUser({
              uid: user.uid, // 사용자 UID 추가
              email: userData.email, // Firestore에서 가져온 이메일
              name: userData.name, // Firestore에서 가져온 이름
              company: userData.company, // Firestore에서 가져온 회사
            });
          } else {
            // Firestore에 사용자 정보가 없는 경우, Authentication에서 기본 정보 사용
            setCurrentUser({
              uid: user.uid,
              email: user.email,
              name: user.displayName || "익명 사용자",
              company: null, // 회사 정보가 없는 경우
            });
          }
        } catch (error) {
          console.error("Error fetching user data: ", error);
        }
      } else {
        // 사용자가 로그아웃한 경우
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return currentUser;
};

export default useAuth;
