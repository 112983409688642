import React from 'react';

// 기존 fieldMappings와 카테고리 번역 함수는 변경 없이 유지
const fieldMappings = {
  category: '작업 분류',
  date: '날짜',
  place: '장소',
  unit: '호기',
  workers: '동행 작업자',
  arrivalTime: '도착 시간',
  completionTime: '완료 시간',
  urgentContent: '작업 내용',
  parts: '사용 부품',
  measures: '조치 사항',
  resultOfAction: '조치 결과',
  specialNotes: '특이 사항',
  images: '현장 사진',
};

const translateCategoryValue = (key, value) => {
  const categoryMappings = {
    inspection: '점검',
    change: '고장',
    emergency: '승객갇힘',
    repair: '수리공사',
  };
  return key === 'category' ? categoryMappings[value] || value : value;
};

const FormConfirmModal = ({ formData, onClose, onSave }) => {
  // 값 렌더링 로직에 부품 정보 렌더링을 추가
  const renderValue = (key, value) => {
    if (key === 'images' && Array.isArray(value)) {
      return value.map((imageObj, index) => (
        <img key={index} src={imageObj.url} alt={`Uploaded ${index}`} style={{ maxWidth: '100px', display: 'block', margin: 'auto' }} />
      ));
    } else if (key === 'workers') {
      return Array.isArray(value) ? value.join(', ') : value;
    } else if (key === 'parts' && Array.isArray(value)) { // 부품 정보 렌더링 추가
      return value.map((part, index) => (
        <div key={index}>{`${part.name} / ${part.quantity}개`}</div>
      ));
    }
    return translateCategoryValue(key, value);
  };

  // 정렬된 폼 데이터 렌더링 로직 변경 없음
  const renderFormData = () => {
    const orderedFormData = Object.keys(fieldMappings)
      .filter(field => formData.hasOwnProperty(field))
      .map(field => ({ key: field, value: formData[field] }));
    
    return (
      <div className="overflow-auto max-h-80" style={{ maxHeight: '80vh' }}>
        <table className="min-w-full leading-normal text-sm">
          <tbody>
            {orderedFormData.map(({ key, value }) => (
              <tr key={key} className="border-b border-gray-200">
                <td className="px-5 py-2 bg-gray-50 text-gray-900 font-medium w-1/3">
                  {fieldMappings[key]}
                </td>
                <td className="px-5 py-2 text-gray-700">
                  {renderValue(key, value)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  // 모달 UI 렌더링 변경 없음
  return (
    <div className="fixed flex-col inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="relative p-5 border w-4/5 max-w-4xl shadow-lg rounded-md bg-white overflow-auto mt-10">
        <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">작업일지 확인</h3>
        <div className="mt-2">
          {renderFormData()}
        </div>
        <div className="flex justify-center items-center mt-4 space-x-4">
          <button onClick={onClose} className="bg-gray-200 hover:bg-gray-300 text-black font-bold py-2 px-4 rounded text-sm">
            취소
          </button>
          <button onClick={onSave} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded text-sm">
            저장
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormConfirmModal;
