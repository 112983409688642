import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBeN3Ih-BupCfDlI8_5F18JB-nDhf_LPJQ",
  authDomain: "elev-prompt.firebaseapp.com",
  projectId: "elev-prompt",
  storageBucket: "elev-prompt.appspot.com",
  messagingSenderId: "767739093024",
  appId: "1:767739093024:web:5afd720772fd8a96dfdc45",
  measurementId: "G-5EHV1RC46X"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app); // 추가

export { app, db, auth, functions };
