import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../Firebase';
import { onAuthStateChanged } from 'firebase/auth';

const SlidingMenu = ({ isOpen, toggleMenu }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  // 관리자 이메일 리스트
  const adminEmails = ['quietjean@naver.com', 'admin@admin.com'];

  // 현재 로그인한 사용자가 관리자인지 확인
  const isAdmin = user && adminEmails.includes(user.email);

  return (
    <>
      <div
        className={`fixed inset-0 z-40 ${isOpen ? 'block' : 'hidden'} bg-black opacity-50`}
        onClick={toggleMenu}
      ></div>

      <div
        className={`fixed top-0 left-0 z-50 w-56 h-full bg-light-tone p-5 transform transition-transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } font-gothic pl-10`}
      >
        {/* 닫기 버튼 추가 */}
        <button 
          onClick={toggleMenu} 
          className="absolute top-0 mt-5 text-2xl font-semibold"
        >
          &times;
        </button>
        
        <nav className="pt-16">
          <Link to="/" className="block py-2 text-black font-bold hover:text-custom-blue" onClick={toggleMenu}>홈</Link>
          {user && (
            <>
              <Link to="/record-work-log" className="block py-2 text-black font-bold hover:text-custom-blue" onClick={toggleMenu}>작업일지 작성</Link>
              <Link to="/work-logs" className="block py-2 text-black font-bold hover:text-custom-blue" onClick={toggleMenu}>작업일지 조회</Link>
              <Link to="/dashboard" className="block py-2 text-black font-bold hover:text-custom-blue" onClick={toggleMenu}>데이터 분석자료</Link>
              {isAdmin && (
                <>
                  <Link to="/admin-dashboard" className="block py-2 text-black font-bold hover:text-custom-blue" onClick={toggleMenu}>계정/현장명 관리</Link>
                  <Link to="/inventory-management" className="block py-2 text-black font-bold hover:text-custom-blue" onClick={toggleMenu}>재고관리</Link>
                  {/* <Link to="/migrate" className="block py-2 text-black font-bold hover:text-custom-blue" onClick={toggleMenu}>작업통합</Link> */}
                </>
              )}
            </>
          )}
        </nav>
      </div>
    </>
  );
};

export default SlidingMenu;
