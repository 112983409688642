// src/pages/SignupPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // 추가
import { doc, setDoc, collection, getDocs } from 'firebase/firestore';
import { auth, db } from '../Firebase';
import { fetchSignInMethodsForEmail, createUserWithEmailAndPassword } from 'firebase/auth';

const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailDuplicateError, setEmailDuplicateError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [company, setCompany] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [companies, setCompanies] = useState([]); // 회사명 목록

  const navigate = useNavigate(); // 추가

  // 이메일 유효성 검사
  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  // 비밀번호 유효성 검사
  const validatePassword = (password) => {
    const re = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return re.test(password);
  };

  // 핸드폰 번호 정규화 (공백과 하이픈 제거)
  const normalizePhoneNumber = (phone) => {
    return phone.replace(/[ -]/g, '');
  };

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 최상단으로 이동
    window.scrollTo(0, 0);
  }, []);

  // 폼 유효성 검사
  useEffect(() => {
    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password);
    const isPasswordMatch = password === confirmPassword;

    setEmailError(isEmailValid || !email ? '' : '유효한 이메일 주소가 아닙니다.');
    setPasswordError(isPasswordValid || !password ? '' : '비밀번호는 8자 이상이며 특수문자를 포함해야 합니다.');
    setConfirmPasswordError(isPasswordMatch || !confirmPassword ? '' : '비밀번호가 일치하지 않습니다.');
    setIsFormValid(isEmailValid && isPasswordValid && isPasswordMatch);
  }, [email, password, confirmPassword]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailDuplicateError(''); // 이메일을 변경하면 중복 오류 메시지 초기화
  };

  useEffect(() => {
    // Firebase Firestore에서 회사명 목록 불러오기
    const fetchCompanies = async () => {
      const querySnapshot = await getDocs(collection(db, "company"));
      const companyNames = [];
      querySnapshot.forEach((doc) => {
        companyNames.push(doc.data().CorporateName);
      });
      setCompanies(companyNames);
    };

    fetchCompanies();
  }, []);

  // 회원가입 처리
  const handleSignup = () => {
    if (isFormValid) {
      // 이메일 중복 검사
      fetchSignInMethodsForEmail(auth, email)
        .then((signInMethods) => {
          if (signInMethods.length === 0) {
            // 이메일이 사용되지 않았으므로 회원가입 진행
            createUserWithEmailAndPassword(auth, email, password)
              .then(async (userCredential) => {
                // 회원가입 성공 시 Firestore에 사용자 정보 저장
                const user = userCredential.user;
                await setDoc(doc(db, "users", user.uid), {
                  email, name, company, phoneNumber, position,
                  // 여기에 추가적인 사용자 정보를 저장할 수 있습니다
                  isApproved: false // 사용자 승인 필드 추가
                });

                // 여기에 성공 메시지 또는 추가 처리 로직을 추가할 수 있습니다
                alert('회원가입이 성공적으로 완료되었습니다.');
                navigate('/login');
              })
              .catch((error) => {
                // 회원가입 오류 처리
                console.error('Signup error', error);
                alert('회원가입 중 오류가 발생했습니다: ' + error.message);
              });
          } else {
            setEmailDuplicateError('이미 사용 중인 이메일 주소입니다.');
          }
        })
        .catch((error) => {
          console.error('Email check error', error);
        });
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col items-center">
        <input
          type="email"
          placeholder="이메일"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2"
          value={email}
          onChange={handleEmailChange}
        />
        {emailDuplicateError && <p className="text-red-500 text-xs">{emailDuplicateError}</p>}
        {email && !validateEmail(email) && <p className="text-red-500 text-xs">{emailError}</p>}

        <input
          type="password"
          placeholder="비밀번호"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {password && !validatePassword(password) && <p className="text-red-500 text-xs">{passwordError}</p>}

        <input
          type="password"
          placeholder="비밀번호 확인"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        {confirmPassword && password !== confirmPassword && <p className="text-red-500 text-xs">{confirmPasswordError}</p>}

        {/* 회사명 선택 드롭다운 */}
        <select
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2"
          value={company}
          onChange={(e) => setCompany(e.target.value)}
        >
          <option value="">회사를 선택하세요</option>
          {companies.map((companyName, index) => (
            <option key={index} value={companyName}>{companyName}</option>
          ))}
        </select>

        {/* 핸드폰 번호 입력 */}
        <input
          type="tel"
          placeholder="핸드폰 번호"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(normalizePhoneNumber(e.target.value))}
        />

        {/* 이름 입력 */}
        <input
          type="text"
          placeholder="이름"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        {/* 직책 입력 */}
        <input
          type="text"
          placeholder="직책"
          className="input input-bordered w-full max-w-xs my-2 h-12 border pl-2"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
        />

        {/* 회원가입 버튼 크기 조정 */}
        <button
          className="btn mt-4 px-10 py-4 bg-gray-800 text-white text-lg" // 버튼 스타일 변경 (더 크게)
          onClick={handleSignup}
          disabled={!isFormValid}
        >
          회원가입
        </button>
      </div>
    </div>
  );
};

export default SignupPage;
