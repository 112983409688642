import React, { useState } from 'react';
import controllerImg from '../../assets/controller.gif';
import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import PersonWorkData from '../dashboard/PersonWorkData';

const ControllerButton = () => {
  const user = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleController = () => {
    setIsOpen(!isOpen);
  };

  const goToWorkLogPage = () => {
    navigate('/record-work-log');
    setIsOpen(false);
  };

  const goToWorkLogs = () => {
    navigate('/work-logs');
    setIsOpen(false);
  };

  // 추가된 함수
  const goToDashboard = () => {
    navigate('/dashboard');
    setIsOpen(false);
  };

  if (!user) {
    return null;
  }

  const expandedClass = "fixed bottom-6 right-6 z-50";
  const contentClass = isOpen ? "scale-95" : "scale-0";
  const baseContentClass = "w-[400px] h-auto bg-white border-2 border-gray-200 rounded-lg p-4 shadow-lg absolute bottom-4 right-4 origin-bottom-right"; 

  return (
  <div className={expandedClass}>
    <div className={`${baseContentClass} transition-transform duration-500 ease-out transform ${contentClass}`}>
      <p className="mb-4 text-center">🕹 {user.name} 님, 항상 안전이 우선입니다.</p>
      {/* 세로 정렬을 위해 flex-col 추가하고 space-x 제거 */}
      <div className="flex flex-col items-center space-y-2">
        <button onClick={goToWorkLogs} className="w-64 py-2 px-4 border border-black bg-white text-black font-bold rounded text-base">
          📄 작업일지 조회
        </button>
        <button onClick={goToWorkLogPage} className="w-64 py-2 px-4 border border-black bg-white text-black font-bold rounded text-base">
          ✍🏻 작업일지 작성
        </button>
        <button onClick={goToDashboard} className="w-64 py-2 px-4 border border-black bg-white text-black font-bold rounded text-base">
          📊 데이터 분석자료
        </button>
      </div>
      <div className="text-center">
        <PersonWorkData />
      </div>
    </div>
    <button onClick={toggleController} className="flex justify-center items-center w-12 h-12 rounded-full bg-blue-500 text-white shadow-lg absolute bottom-0 right-0">
      <img src={controllerImg} alt="Controller" className="w-full h-full"/>
    </button>
  </div>
);

};

export default ControllerButton;