import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../../Firebase';
import { collection, getDocs } from 'firebase/firestore';

const RecordPlace = ({ onPlaceSelect, selectedPlace }) => {
  const [places, setPlaces] = useState([]);
  const [searchTerm, setSearchTerm] = useState(selectedPlace || ""); // 초기 상태를 selectedPlace로 설정
  const [showSuggestions, setShowSuggestions] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const fetchPlaces = async () => {
      const snapshot = await getDocs(collection(db, "customerData"));
      const placesData = [];
      snapshot.forEach(doc => {
        placesData.push(doc.data().place);
      });
      setPlaces([...new Set(placesData)]);
    };

    fetchPlaces();
  }, []);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (selectedPlace) {
      setSearchTerm(selectedPlace); // 초기 검색어를 선택된 현장명으로 설정
    }
  }, [selectedPlace]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (place) => {
    setSearchTerm(place);
    setShowSuggestions(false);
    onPlaceSelect(place);
  };

  const clearSearch = () => {
    setSearchTerm('');
    onPlaceSelect('');
  };

  return (
    <div ref={wrapperRef} className="relative w-full">
      <label htmlFor="place-search" className="block text-sm font-medium text-gray-700">현장명(필수)</label>
      <div className="flex items-center border border-gray-300 rounded-md">
        <input
          id="place-search"
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={() => setShowSuggestions(true)}
          className="w-full p-2 rounded-md"
          placeholder="현장명을 검색하세요."
          autoComplete="off" // 자동완성 비활성화
        />
        {searchTerm && (
          <div
            className="p-2 cursor-pointer"
            onClick={clearSearch}
          >
            <svg
              className="w-4 h-4 text-gray-500"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </div>
        )}
      </div>
      {showSuggestions && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto">
          {places.filter(place => place.toLowerCase().includes(searchTerm.toLowerCase())).map((place, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(place)}
              className="p-2 hover:bg-gray-100 cursor-pointer"
            >
              {place}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default RecordPlace;
