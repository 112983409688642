import React, { useState, useEffect } from 'react';
import { db } from '../../Firebase';
import DeleteConfirmModal from '../ui/modal/DeleteInvenModal';
import InventoryList from './inventory/InventoryList';
import InvenLogModal from '../ui/modal/InvenLogModal';
import { collection, addDoc, doc, updateDoc, getDocs, getDoc, deleteDoc } from 'firebase/firestore';

const InventoryManagement = () => {
  const [inventoryItems, setInventoryItems] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [isLogModalOpen, setIsLogModalOpen] = useState(false);
  const [currentItemName, setCurrentItemName] = useState('');
  const [newUnifiedData, setNewUnifiedData] = useState([]);

  // Fetch inventory items from Firestore
  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'inventoryItems'));
      const items = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        isEditing: false // 페이지 새로고침 시 모든 항목을 비편집 상태로 초기화
      }));
      setInventoryItems(items);
    };

    fetchItems();
  }, []);

  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(collection(db, 'inventoryItems'));
      const items = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        isEditing: false // 페이지 새로고침 시 모든 항목을 비편집 상태로 초기화
      }));
      setInventoryItems(items);
    };

    // newUnifiedData 가져오기
    const fetchNewUnifiedData = async () => {
      const querySnapshot = await getDocs(collection(db, 'newUnifiedData')); // 가정: newUnifiedData 컬렉션
      const unifiedData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNewUnifiedData(unifiedData);
    };

    fetchItems();
    fetchNewUnifiedData();
  }, []);

  // Firestore에 새 항목 추가를 담당하는 함수
  const addNewItemToFirestore = async (item) => {
    const docRef = await addDoc(collection(db, 'inventoryItems'), item);
    const modificationsRef = collection(db, `inventoryItems/${docRef.id}/modifications`);
    const initialModification = {
      modifiedAt: new Date(),
      changes: Object.keys(item)
        .filter(key => key !== 'isEditing' && key !== 'id' && !(key === 'stock' && item[key] === 0))
        .map(key => ({
          field: key,
          from: "0",
          to: item[key],
        })),
    };
    await addDoc(modificationsRef, initialModification);

    return docRef.id;
  };

  // Firestore의 항목을 업데이트하는 함수
  const updateItemInFirestore = async (id, updatedItem) => {
    try {
      const docRef = doc(db, 'inventoryItems', id);
      await updateDoc(docRef, updatedItem);
      console.log("Document updated with ID: ", id); // 성공적으로 업데이트된 경우, 콘솔에 ID 로깅
    } catch (error) {
      console.error("Error updating item: ", error);
      throw new Error(error); // 오류 발생 시 외부로 예외 던지기
    }
  };

  const deleteItemFromFirestore = async (id) => {
    await deleteDoc(doc(db, 'inventoryItems', id));
  };

  // 새 항목을 파이어스토어에 추가하고, 로컬 상태를 업데이트하는 로직
  const handleAddNewItem = async () => {
    const newItem = { name: '', stock: 0, memo: '', isEditing: true }; // 새 항목의 기본 구조
    const docId = await addNewItemToFirestore(newItem); // Firestore에 새 항목 추가
    const addedItem = { ...newItem, id: docId }; // 로컬 상태 업데이트를 위한 항목 객체 생성
    setInventoryItems(prevItems => [...prevItems, addedItem]); // 상태 업데이트로 UI에 항목 추가 반영
  };

  const handleInputChange = (id, field, value) => {
    setInventoryItems(inventoryItems.map(item => item.id === id ? { ...item, [field]: value } : item));
  };

  const handleSaveItem = async (id) => {
    const itemToSave = inventoryItems.find(item => item.id === id);
    if (!itemToSave) {
      console.error('Item not found.');
      return;
    }

    const originalItem = await getDoc(doc(db, 'inventoryItems', id));
    if (!originalItem.exists()) {
      console.error('Original item does not exist.');
      return;
    }

    await updateItemInFirestore(id, itemToSave); // 항목 업데이트

    // 수정 내역 생성 (undefined 값을 포함하지 않도록 주의)
    const modification = {
      modifiedAt: new Date(),
      changes: Object.keys(itemToSave).reduce((acc, key) => {
        const originalValue = originalItem.data()[key];
        const newValue = itemToSave[key];

        // undefined 값을 제외하고, 변경된 값만을 처리
        if (newValue !== undefined && newValue !== originalValue) {
          acc.push({ field: key, from: originalValue ?? null, to: newValue });
        }
        return acc;
      }, []),
    };

    // modifications 서브컬렉션에 수정 내역 저장 (수정된 필드가 있을 때만)
    if (modification.changes.length > 0) {
      const modificationsRef = collection(db, `inventoryItems/${id}/modifications`);
      await addDoc(modificationsRef, modification).catch(error => console.error("Error adding document: ", error));
    }

    // 로컬 상태 업데이트로 UI 반영
    setInventoryItems(prevItems => prevItems.map(item => item.id === id ? { ...itemToSave, isEditing: false } : item));
  };

  const handleEditItem = (id) => {
    setInventoryItems(inventoryItems.map(item => item.id === id ? { ...item, isEditing: true } : item));
  };

  const handleShowDeleteModal = (id) => {
    setSelectedItemId(id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteItemFromFirestore = async () => {
    await deleteItemFromFirestore(selectedItemId);
    handleDeleteItem(selectedItemId);
  };

  const handleDeleteItem = (id) => {
    setInventoryItems(inventoryItems.filter(item => item.id !== id));
    setIsDeleteModalOpen(false);
  };

  const handleUsageHistory = (name) => {
    setCurrentItemName(name); // 현재 선택된 항목의 이름을 상태로 저장
    setIsLogModalOpen(true); // 모달 열기
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  return (
    <div className="container mx-auto p-4 max-w-full">
      <h1 className="text-2xl font-bold text-center mb-2">⚙️ 재고 관리 ⚙️</h1>
      <h6 className="text-center mb-8">테블릿, PC 환경에서 작업을 권장합니다.</h6>
      <InventoryList
        inventoryItems={inventoryItems}
        handleInputChange={handleInputChange}
        handleSaveItem={handleSaveItem}
        handleEditItem={handleEditItem}
        handleShowDeleteModal={handleShowDeleteModal}
        handleUsageHistory={handleUsageHistory}
      />
      <div className="mt-4 flex justify-center">
        <button
          onClick={handleAddNewItem}
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
          추가하기
        </button>
      </div>
      {isDeleteModalOpen && (
        <DeleteConfirmModal
          onClose={handleCloseDeleteModal}
          onConfirm={handleDeleteItemFromFirestore}
        />
      )}
      {isLogModalOpen && (
        <InvenLogModal
          isOpen={isLogModalOpen}
          onClose={() => setIsLogModalOpen(false)}
          itemName={currentItemName}
          newUnifiedData={newUnifiedData}
          inventoryItems={inventoryItems} // 여기에서 inventoryItems 상태를 전달하고 있음을 확인
        />
      )}
    </div>
  );
};

export default InventoryManagement;