// src/components/WorkLogCard.js

import React from 'react';

const workTypeColors = {
  change: 'bg-yellow-500 text-white px-2 py-1 rounded', // 고장 - 노란색
  emergency: 'bg-red-500 text-white px-2 py-1 rounded', // 승객갇힘 - 빨간색
  inspection: 'bg-green-500 text-white px-2 py-1 rounded', // 점검 - 초록색
  repair: 'bg-blue-500 text-white px-2 py-1 rounded', // 수리공사 - 파란색
  etc: 'bg-gray-500 text-white px-2 py-1 rounded' // 기타 - 회색
};


const WorkLogCard = ({ log, onClick }) => {
  const { category, createdAt, place, creatorName, unit } = log;
  const workType = (() => {
    switch (category) {
      case 'change': return '💥 고장';
      case 'emergency': return '🚨 승객갇힘';
      case 'inspection': return '🔍 점검';
      case 'repair': return '🛠 수리공사';
      case 'etc': return '📝 기타';
      default: return '';
    }
  })();

  // WorkLogCard 컴포넌트 내부에서 formatDate 함수 정의 부분
  const formatDate = (dateInput) => {
    // dateInput이 Date 객체가 아닐 경우 toDate()를 호출하여 변환
    const date = dateInput instanceof Date ? dateInput : new Date(dateInput.seconds * 1000);
    const dayNames = ['(일)', '(월)', '(화)', '(수)', '(목)', '(금)', '(토)'];
    const dayName = dayNames[date.getDay()];

    return `${date.toLocaleDateString('ko-KR')} ${dayName}`;
  };

  return (
    <div className="work-log-card cursor-pointer" onClick={onClick}>
      <div className="flex flex-col p-4 m-2 rounded shadow-lg bg-white py-1">
        <div className="flex justify-between items-center mb-1">
          <div className="text-gray-500 text-xs">날짜 <span className="text-black font-bold text-sm">{formatDate(createdAt)}</span></div>
          <div className="text-gray-500 text-xs">작업자 <span className="text-black font-bold text-sm">{creatorName}</span></div>
          <div className={`px-3 py-1 rounded ${workTypeColors[category]}`}>
            {workType} {/* 이모지는 workType 내용에 따라 동적으로 추가해야 합니다 */}
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="text-gray-500 text-xs">현장명 <span className="text-black font-bold text-sm">{place}</span></div>
          {/* '호기'에 대한 데이터가 log 객체에 포함되어 있다고 가정하고, 해당 데이터를 표시합니다. */}
          <div className="text-gray-500 text-xs">호기 <span className="text-black font-bold text-sm">{unit}</span></div>
        </div>
      </div>
    </div>
  );
};

export default WorkLogCard;
