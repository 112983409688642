import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase'; // Firestore 인스턴스를 임포트하는 경로는 프로젝트 설정에 따라 다를 수 있습니다.

const TotalEl = () => {
  const [unitTotal, setUnitTotal] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchUnitTotal = async () => {
      const querySnapshot = await getDocs(collection(db, "customerData"));
      let totalUnits = 0;
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.unit && Array.isArray(data.unit)) {
          totalUnits += data.unit.length; // 각 문서의 unit 배열 길이를 합산
        }
      });
      setUnitTotal(totalUnits); // unit의 총 개수 상태 업데이트
    };

    fetchUnitTotal();
  }, []);

  useEffect(() => {
    // 숫자 카운팅 애니메이션
    if (count < unitTotal) {
      const timeout = setTimeout(() => setCount(count + 2), 3);
      return () => clearTimeout(timeout);
    }
  }, [count, unitTotal]);

  return (
    <div className="flex justify-center mt-10">
      <div className="bg-gradient-to-br from-purple-400 via-pink-500 to-red-500 text-white text-center p-6 rounded-lg shadow-xl" style={{ width: '300px', height: '300px' }}>
        <h2 className="text-xl font-bold mb-2">총 엘리베이터 수</h2>
        <div className="text-6xl font-bold mb-2">
          {count}
        </div>
        <p className="text-lg">
          우리는 도시를 책임지고 있습니다. 엘리베이터는 도시의 발전에 아주 큰 기여를 했습니다. 우리의 기술로 도시를 발전시키고, 움직이고 있습니다.
        </p>
      </div>
    </div>
  );
};

export default TotalEl;
