import React, { useEffect, useState } from 'react';
import { db } from '../../Firebase';
import { collection, getDocs } from 'firebase/firestore';

const WorkerLog = () => {
  const [workerCounts, setWorkerCounts] = useState({});

  useEffect(() => {
    const fetchWorkLogs = async () => {
      const snapshot = await getDocs(collection(db, "newUnifiedData"));
      const counts = {};

      snapshot.forEach(doc => {
        const data = doc.data();
        if (data.creatorName) {
          counts[data.creatorName] = (counts[data.creatorName] || 0) + 1;
        }
        data.workers?.forEach(worker => {
          counts[worker] = (counts[worker] || 0) + 1;
        });
      });

      setWorkerCounts(counts);
    };

    fetchWorkLogs();
  }, []);

  // 내림차순으로 정렬된 작업자 배열 생성 및 이름에 공백이 포함된 항목 제외
  const sortedWorkers = Object.entries(workerCounts)
    .filter(([name]) => !name.includes(' ')) // 이름에 공백이 포함된 항목 제외
    .sort((a, b) => b[1] - a[1]); // 내림차순 정렬

  return (
    <div className="flex flex-wrap justify-center items-center mt-10">
      <h2 className="text-2xl font-bold mb-4 text-center">🧑🏻‍💼 작엽자 별 작업일지 작성 수 </h2>
      <div className="w-full flex flex-wrap justify-center">
        {sortedWorkers.map(([worker, count]) => (
          <div key={worker} className="m-2 bg-gradient-to-br from-teal-400 to-blue-600 text-white text-center p-3 rounded-lg shadow-xl" style={{ width: '150px', height: '100px' }}>
            <h2 className="text-md font-bold mb-1">{worker}</h2>
            <div className="text-3xl font-bold">{count}</div>
            <p className="text-sm">건의 작업일지 작성</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WorkerLog;
