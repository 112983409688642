import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../../Firebase'; // 실제 경로에 맞게 조정하세요.
import { collection, query, where, getDocs } from 'firebase/firestore';
import useAuth from '../../../hooks/useAuth';

const RecordWorker = ({ onWorkersSelect, selectedWorkers = [] }) => {
  const [workers, setWorkers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [selectedWorkerList, setSelectedWorkerList] = useState(selectedWorkers); 
  const wrapperRef = useRef(null);
  const currentUser = useAuth();

  useEffect(() => {
    const fetchWorkers = async () => {
      const q = query(collection(db, "users"), where("isApproved", "==", true));
      const snapshot = await getDocs(q);
      const workersData = [];
      snapshot.forEach(doc => {
        const workerData = { id: doc.id, name: doc.data().name, company: doc.data().company };
        // 조건 1: 로그인된 사용자 제외
        // 조건 2: 같은 회사에 속한 사용자만 포함
        if (currentUser && workerData.id !== currentUser.uid && workerData.company === currentUser.company) {
          workersData.push(workerData.name); // 변경: 작업자 객체 대신 이름만 저장
        }
      });
      setWorkers(workersData);
    };

    if (currentUser) {
      fetchWorkers();
    }
  }, [currentUser]);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (workerName) => {
    if (!selectedWorkerList.includes(workerName)) {
      const newList = [...selectedWorkerList, workerName]; // 이름 문자열을 리스트에 추가
      setSelectedWorkerList(newList);
      onWorkersSelect(newList); // 이름 문자열 리스트를 그대로 전달
    }
    setSearchTerm('');
    setShowSuggestions(false);
  };

  const handleRemoveWorker = (workerName) => {
    const newList = selectedWorkerList.filter(name => name !== workerName); // 이름 문자열을 기준으로 필터링
    setSelectedWorkerList(newList);
    onWorkersSelect(newList); // 이름 문자열 리스트를 그대로 전달
  };

  return (
    <div ref={wrapperRef} className="relative w-full">
      <label htmlFor="worker-search" className="block text-sm font-medium text-gray-700">동행 작업자 추가(선택)</label>
      <div className="flex items-center border border-gray-300 rounded-md">
        <input
          id="worker-search"
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onFocus={() => setShowSuggestions(true)}
          className="w-full p-2 rounded-md"
          placeholder="함께 작업한 작업자 이름 검색"
          autoComplete="off"
        />
      </div>
      {showSuggestions && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto">
          {workers.filter(name => name.toLowerCase().includes(searchTerm.toLowerCase())).map((name, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(name)}
              className="p-2 hover:bg-gray-100 cursor-pointer"
            >
              {name}
            </li>
          ))}
        </ul>
      )}
      <div className="mt-2">
        {selectedWorkerList.map((name, index) => (
          <div key={index} className="inline-flex items-center bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
            {name} {/* 작업자의 이름을 직접 표시 */}
            <button
              onClick={() => handleRemoveWorker(name)} // 작업자 이름을 인자로 전달
              className="ml-2 text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecordWorker;
