import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc, query, where } from 'firebase/firestore';
import { db } from '../../Firebase';
import EmployeeModal from '../ui/modal/EmployeeModal';

const EmployeeStatus = () => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentUserToDelete, setCurrentUserToDelete] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      // 'isApproved' 필드가 true인 사용자만 조회
      const q = query(collection(db, 'users'), where("isApproved", "==", true));
      const querySnapshot = await getDocs(q);
      setUsers(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchUsers();
  }, []);

  const handleDeleteClick = (user) => {
    setCurrentUserToDelete(user);
    setIsModalOpen(true);
  };

  const deleteUser = async () => {
    if (currentUserToDelete) {
      await deleteDoc(doc(db, 'users', currentUserToDelete.id));
      // 사용자 삭제 후 목록 업데이트
      setUsers(users.filter(user => user.id !== currentUserToDelete.id));
      setIsModalOpen(false);
      setCurrentUserToDelete(null);
    }
  };

  return (
    <div>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-4 py-2">이름</th>
              <th className="px-4 py-2">이메일</th>
              <th className="px-4 py-2">조치</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <tr key={user.id} className="bg-white border-b">
                <td className="px-4 py-2">{user.name}</td>
                <td className="px-4 py-2">{user.email}</td>
                <td className="px-4 py-2">
                  <button 
                    onClick={() => handleDeleteClick(user)} 
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                  >
                    삭제
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <EmployeeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="p-4">
          <p className="mb-4">정말로 '{currentUserToDelete?.name}' 사용자를 삭제하시겠습니까?</p>
          <div className="flex justify-end space-x-2">
            <button onClick={deleteUser} className="py-2 px-4 bg-red-500 hover:bg-red-700 text-white rounded">
              삭제
            </button>
            <button onClick={() => setIsModalOpen(false)} className="py-2 px-4 bg-gray-500 hover:bg-gray-700 text-white rounded">
              취소
            </button>
          </div>
        </div>
      </EmployeeModal>
    </div>
  );
};

export default EmployeeStatus;
