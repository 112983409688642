// src/pages/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import AdminMenu from '../components/ui/AdminMenu';
import AdminAccount from '../components/auth/AdminAccount';
import PlaceManager from '../components/workplace/PlaceManager';

const AdminDashboard = () => {
  const [selectedMenu, setSelectedMenu] = useState('account');

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 최상단으로 이동
    window.scrollTo(0, 0);
  }, []);

  const renderComponent = () => {
    switch (selectedMenu) {
      case 'account':
        return <AdminAccount />;
      case 'place':
        return <PlaceManager />;
      default:
        return <AdminAccount />;
    }
  };

  return (
    <div>
      <AdminMenu setSelectedMenu={setSelectedMenu} />
      {renderComponent()}
    </div>
  );
};

export default AdminDashboard;
