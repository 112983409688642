import React from 'react';
import { useNavigate } from 'react-router-dom';
import WorkLogCard from './WorkLogCard';

const SameWorkLog = ({ relatedWorkLogs, currentWorkLogId }) => {
  const navigate = useNavigate(); // useNavigate 훅 사용

  // 클릭 이벤트 핸들러 정의
  const handleClick = (workLogId) => {
    navigate(`/work-logs/${workLogId}`); // 세부 정보 페이지로 이동
  };

  // 날짜 내림차순으로 정렬된 작업일지 배열 생성
  const sortedWorkLogs = relatedWorkLogs.sort((a, b) => {
    // 날짜를 나타내는 필드가 'date'라고 가정
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <div>
      {sortedWorkLogs.length > 0 && (
        <div>
          <h3 className="flex justify-center text-lg font-bold my-4">현장명, 호기가 같은 작업일지 📄 (최근 작업순)</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {sortedWorkLogs.map(log => (
              // WorkLogCard 클릭 이벤트에 handleClick 함수 연결
              <div key={log.id} onClick={() => handleClick(log.id)}>
                <WorkLogCard log={log} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SameWorkLog;
