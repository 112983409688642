import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../../Firebase';

const UserInfoEditForm = ({ userInfo }) => {
  const [formValues, setFormValues] = useState({
    phoneNumber: userInfo.phoneNumber || '',
    name: userInfo.name || '',
    position: userInfo.position || '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!userInfo || !userInfo.uid) {
      console.error('No user ID available');
      return;
    }
    try {
      const userDocRef = doc(db, "users", userInfo.uid);
      await updateDoc(userDocRef, {
        phoneNumber: formValues.phoneNumber,
        name: formValues.name,
        position: formValues.position,
        // 여기서 email과 company를 제외했습니다.
      });
      alert('정보가 성공적으로 업데이트되었습니다.');
    } catch (error) {
      console.error('정보 업데이트 실패', error);
      alert('정보 업데이트 중 오류가 발생했습니다: ' + error.message);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, userInfo.email);
      alert('비밀번호 초기화 메일이 발송되었습니다.');
    } catch (error) {
      console.error('비밀번호 초기화 메일 발송 에러', error);
      alert('비밀번호 초기화 메일 발송 중 오류가 발생했습니다: ' + error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">이메일(변경불가)</label>
        <div className="mt-1 block w-full border border-gray-300 p-2 shadow-sm">{userInfo?.email}</div>
      </div>
      <div className="mb-4">
        <button type="button" onClick={handlePasswordReset} className="text-sm px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
          비밀번호 초기화 메일 발송
        </button>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">회사(변경불가)</label>
        <div className="mt-1 block w-full border border-gray-300 p-2 shadow-sm">{userInfo?.company}</div>
      </div>
      {[
        { id: 'phone', label: '핸드폰 번호', value: formValues.phoneNumber, type: 'text' },
        { id: 'name', label: '이름', value: formValues.name, type: 'text' },
        { id: 'position', label: '직책', value: formValues.position, type: 'text' },
      ].map(field => (
        <div key={field.id} className="mb-4">
          <label htmlFor={field.id} className="block text-sm font-medium text-gray-700">{field.label}</label>
          <input
            type={field.type}
            id={field.id}
            name={field.id}
            value={field.value}
            onChange={handleChange}
            className="mt-1 block w-full border border-gray-300 p-2 shadow-sm"
          />
        </div>
      ))}
      <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
        변경 사항 저장
      </button>
    </form>
  );
};

export default UserInfoEditForm;
