import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
import serviceImg from '../assets/service01.png';
import serviceImg2 from '../assets/service02.png';

const HomePage = () => {

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 최상단으로 이동
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto px-4">
      <div className="bg-blue-600 text-white text-center py-2 px-4 rounded-full mx-auto max-w-max mt-24">
        <p className="text-xl font-semibold ml-2 mr-2">빠르게, 정확하게, 어디서든</p>
      </div>
      <header className="text-center my-8 mt-12">
        <h1 className="text-5xl font-bold">편리한 작업관리</h1>
        <p className="text-xl text-gray-700 mt-4">
          작업자가 어디서든 작업일지를 작성하고, 업무 현황을 파악할 수 있습니다.
        </p>
        {/* <div className="mt-6">
          <Link to="/login" className="mt-6 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            시작하기
          </Link>
        </div> */}
      </header>


      
      <h2 className="flex justify-center text-4xl font-bold my-8 mt-20">혁신적인 업무환경 개선</h2>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="rounded overflow-hidden shadow-lg">
          <img className="w-full" src={serviceImg} alt="service01" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">모바일 업무환경 제공</div>
            <p className="text-gray-700 text-base">
              모바일에서도 빠르고 정확하게 업무일지를 작성할 수 있습니다. 업무의 효율을 극대화 시켜보세요. 너무나 당연하게도 PC, 테블릿, 폴드 환경에서도 완벽하게 사용할 수 있습니다.
            </p>
          </div>
        </div>
        <div className="rounded overflow-hidden shadow-lg">
          <img className="w-full" src={serviceImg2} alt="Large screen guidance" />
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">철저한 데이터분석</div>
            <p className="text-gray-700 text-base">
              작성된 작업일지 데이터를 분석하여 시각화된 자료를 제공합니다. 회사를 운영하는데 필요한 인사이트를 발견해보세요. 우리는 더욱 더 크게 성장 할 수 있을 것입니다.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
