import React, { useState, useEffect, useRef } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL, deleteObject } from "firebase/storage";

const ImageUpload = ({ onUpload, initialImages = [], onImageClick }) => {
  const [uploading, setUploading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const fileInputRef = useRef(null);

  useEffect(() => {
    // 초기 이미지만 처리하는 useEffect
    if (initialImages.length > 0 && uploadedImages.length === 0) {
      setUploadedImages(initialImages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialImages]); // initialImages만 의존성 배열에 포함

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  // 이미지 업로드 핸들러
  const handleUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    const storage = getStorage();
    setUploading(true);

    try {
      const uploadPromises = files.map(async (file) => {
        const filePath = `images/${Date.now()}-${file.name}`;
        const storageRef = ref(storage, filePath);

        const uploadTask = uploadBytesResumable(storageRef, file);
        
        // 업로드 진행 상황 모니터링
        uploadTask.on('state_changed', 
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(prev => ({ ...prev, [file.name]: progress }));
          },
          (error) => {
            console.error("업로드 오류:", error);
          }
        );

        await uploadTask;
        const downloadURL = await getDownloadURL(storageRef);
        return { url: downloadURL, path: filePath };
      });

      const newImages = await Promise.all(uploadPromises);
      setUploadedImages(prev => [...prev, ...newImages]);
      onUpload([...uploadedImages, ...newImages]);
    } catch (error) {
      console.error("업로드 중 오류 발생:", error);
    } finally {
      setUploading(false);
      setUploadProgress({});
      event.target.value = ''; // 파일 입력 필드 초기화
    }
  };

  // 이미지 삭제 로직
  const handleDelete = async (imageToDelete, event) => {
    event.preventDefault();
    const storage = getStorage();

    // 이미 업로드된 이미지를 삭제하는 경우, Firebase Storage에서도 삭제
    if (imageToDelete.path) {
      const imageRef = ref(storage, imageToDelete.path);

      try {
        await deleteObject(imageRef); // Firebase Storage에서 이미지 삭제
        console.log('Image deleted from Firebase Storage');
      } catch (error) {
        console.error('Error removing image from Firebase Storage:', error);
        return; // Firebase Storage에서의 삭제 실패 시, 함수를 종료합니다.
      }
    }

    // 로컬 상태에서 이미지 정보 삭제
    const newImages = uploadedImages.filter(image => image.path !== imageToDelete.path);
    setUploadedImages(newImages);
    onUpload(newImages.map(image => ({ url: image.url, path: image.path })));
  };

  return (
    <div>
      <label className="block text-sm font-medium text-gray-700 flex items-center gap-2">
        이미지 업로드
        <button type="button" onClick={triggerFileInput} className="p-1 border rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 4v16c0 1.1.9 2 2 2h12c1.1 0 2-0.9 2-2V4H4zm4 8a4 4 0 108 0 4 4 0 00-8 0z" />
          </svg>
        </button>
      </label>
      <input type="file" ref={fileInputRef} onChange={handleUpload} disabled={uploading} className="hidden" multiple />
      {uploading && (
        <div>
          <p>업로드 중...</p>
          {Object.entries(uploadProgress).map(([fileName, progress]) => (
            <div key={fileName}>
              <span>{fileName}: {progress.toFixed(0)}%</span>
              <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="flex flex-wrap gap-2">
        {uploadedImages.map((image, index) => (
          <div key={index} className="relative" onClick={() => onImageClick(image.url)}>
            <img src={image.url} alt={`Uploaded ${index}`} className="w-20 h-20 object-cover" />
            <button
              type="button"
              onClick={(event) => {
                event.stopPropagation(); // 이벤트 전파 방지
                handleDelete(image, event);
              }}
              className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageUpload;
