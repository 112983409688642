// src/components/ui/ImageSaveButton.js
import React from 'react';

const ImageSaveButton = ({ onSaveAsImage }) => {
  return (
    <button
      onClick={onSaveAsImage}
      className="border-2 border-black text-black bg-white hover:bg-gray-100 font-bold py-2 px-4 rounded"
    >
      저장 💾
    </button>
  );
};

export default ImageSaveButton;
