// src/components/ui/PrintButton.js
import React from 'react';

const PrintButton = () => {
  const handlePrint = () => {
    window.print();
  };

  return (
    <button
      onClick={handlePrint}
      className="border-2 border-black text-black bg-white hover:bg-gray-100 font-bold py-2 px-4 rounded"
    >
      인쇄 🖨
    </button>
  );
};

export default PrintButton;
