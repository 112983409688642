// src/components/LoadingIndicator.js

import React from 'react';
import loadingGif from '../../assets/loading.gif'; // 로딩 이미지 경로

const LoadingIndicator = () => {
  return (
    <div className="flex justify-center items-center">
      <img src={loadingGif} alt="로딩 중..." className="w-20 h-20" />
    </div>
  );
};

export default LoadingIndicator;
