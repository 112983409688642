import React, { useState, useEffect } from 'react';
import { db } from '../../../Firebase';
import { collection, getDocs } from 'firebase/firestore';

const InvenLogModal = ({ isOpen, onClose, itemName, newUnifiedData, inventoryItems }) => {
  const [modifications, setModifications] = useState([]);
  const [finalStock, setFinalStock] = useState(0);

  useEffect(() => {
    const calculateFinalStock = async () => {
      if (!isOpen || !itemName) return;

      const currentItem = inventoryItems.find(item => item.name === itemName);
      if (!currentItem) return;

      const modificationsRef = collection(db, `inventoryItems/${currentItem.id}/modifications`);
      const querySnapshot = await getDocs(modificationsRef);
      const fetchedModifications = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })).sort((a, b) => b.modifiedAt - a.modifiedAt); // 내림차순으로 정렬

      setModifications(fetchedModifications);

      const lastStockValue = fetchedModifications.length > 0
        ? parseInt(fetchedModifications[0].changes.find(change => change.field === 'stock')?.to, 10)
        : currentItem.stock;

      if (!Array.isArray(newUnifiedData)) {
      console.error('newUnifiedData is not an array');
      return;
    }

    const totalUsedQuantity = newUnifiedData.reduce((total, data) => {
      if (!Array.isArray(data.parts)) {
        // parts가 없거나 배열이 아니면 이 항목을 건너뜁니다.
        return total;
      }
      const partUsage = data.parts.find(part => part.name === itemName);
      return total + (partUsage ? partUsage.quantity : 0);
    }, 0);

      setFinalStock(lastStockValue - totalUsedQuantity);
    };

    if (isOpen && itemName) {
    calculateFinalStock();
  }
}, [isOpen, itemName, inventoryItems, newUnifiedData]);

  if (!isOpen) return null;

  // itemName과 일치하는 parts의 name 값을 가진 항목 필터링
  const matchingLogs = newUnifiedData.filter(data => 
    Array.isArray(data.parts) && data.parts.some(part => part.name === itemName)
  );

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={onClose}>
      <div className="relative top-20 mx-auto p-5 border w-3/4 shadow-lg rounded-md bg-white" onClick={e => e.stopPropagation()}>
        <div className="flex justify-between items-center">
          <h2 className="text-xl font-semibold mb-4">{itemName}의 사용 내역</h2>
        </div>
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="border-b-2 border-gray-200 bg-gray-100 text-left px-5 py-3 text-xs font-semibold text-gray-600 uppercase tracking-wider">
                날짜
              </th>
              <th className="border-b-2 border-gray-200 bg-gray-100 text-left px-5 py-3 text-xs font-semibold text-gray-600 uppercase tracking-wider">
                작업자
              </th>
              <th className="border-b-2 border-gray-200 bg-gray-100 text-left px-5 py-3 text-xs font-semibold text-gray-600 uppercase tracking-wider">
                개수
              </th>
              <th className="border-b-2 border-gray-200 bg-gray-100 text-left px-5 py-3 text-xs font-semibold text-gray-600 uppercase tracking-wider">
                장소
              </th>
              {/* 주석 처리된 부분
              <th className="border-b-2 border-gray-200 bg-gray-100 text-left px-5 py-3 text-xs font-semibold text-gray-600 uppercase tracking-wider">
                작업 일지
              </th>
              */}
            </tr>
          </thead>
          <tbody>
            {matchingLogs
              .sort((a, b) => b.date.localeCompare(a.date)) // date 기준으로 내림차순 정렬
              .map((log, index) => (
                <tr key={index}>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {log.date}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {log.creatorName}
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    - {log.parts.find(part => part.name === itemName)?.quantity || '0'}개
                  </td>
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    {log.place}
                  </td>
                  {/* 주석 처리된 버튼
                  <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                    <button
                      onClick={() => {}}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      작업 일지 보기
                    </button>
                  </td>
                  */}
                </tr>
              ))}
          </tbody>
        </table>
        {modifications.length > 0 && (
          <>
            <h3 className="text-lg font-semibold mt-4 mb-4">수정 내역</h3>
            <table className="min-w-full leading-normal">
              <thead>
                <tr>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    재고 수정날짜
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    재고 변경
                  </th>
                  <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    메모
                  </th>
                </tr>
              </thead>
              <tbody>
                {modifications.map((modification, modIndex) => {
                  // Firestore의 Timestamp 객체에서 JavaScript의 Date 객체로 변환
                  const modificationDate = modification.modifiedAt.toDate();
                  // 날짜를 'YYYY-MM-DD' 형식으로 변환
                  const formattedDate = `${modificationDate.getFullYear()}-${String(modificationDate.getMonth() + 1).padStart(2, '0')}-${String(modificationDate.getDate()).padStart(2, '0')}`;

                  return (
                    <tr key={modIndex}>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {formattedDate} {/* 포맷된 날짜 문자열을 표시 */}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {modification.changes
                          .filter(change => change.field === "stock")
                          .map((change, changeIndex) => (
                            <span key={changeIndex}>{`${change.from}개 ➡️ ${change.to}개`}</span>
                          ))}
                      </td>
                      <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                        {modification.changes
                          .filter(change => change.field === "memo")
                          .map((change, changeIndex) => (
                            <span key={changeIndex}>{change.to}</span>
                          ))}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}

        <div className="mt-4">
          <h3 className="text-lg font-semibold">최종 재고 수량: {finalStock}개</h3>
        </div>

        <div className="flex justify-end items-center mt-4">
          <button
            className="bg-blue-500 text-white active:bg-blue-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
            onClick={onClose}
          >
            닫기
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvenLogModal;
