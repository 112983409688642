// src/components/header/Header.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AuthButtons from './AuthButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import SlidingMenu from './SlidingMenu';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <SlidingMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <header className="bg-white text-black p-4 fixed top-0 left-0 right-0 z-30 font-gothic">
        <div className="container mx-auto flex justify-between items-center">
          <FontAwesomeIcon
            icon={isMenuOpen ? faTimes : faBars}
            className="text-xl cursor-pointer text-black hover:text-custom-blue ml-5"
            onClick={toggleMenu}
          />
          <div className="flex-1 ml-8 mt-1">
            <h1 className="text-xl font-bold">
              <Link to="/" className="inline-block"> {/* inline-block으로 변경하여 텍스트 크기만큼만 링크 적용 */}
                서진엘리베이터
              </Link>
            </h1>
          </div>
          <AuthButtons />
        </div>
      </header>
    </>
  );
};

export default Header;
