// src/components/ui/recordForm/RecordActionText.js
import React from 'react';

const RecordActionText = ({ urgentContent, onTextChange }) => {
  return (
    <div>
      <label htmlFor="urgent-content" className="block text-sm font-medium text-gray-700">
        작업 내용
      </label>
      <textarea
        id="urgent-content"
        name="urgentContent"
        value={urgentContent}
        onChange={onTextChange}
        rows="3"
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        placeholder="작업한 내용을 입력하세요."
      />
    </div>
  );
};

export default RecordActionText;
