import React from 'react';

const AdminMenu = ({ setSelectedMenu }) => {
  return (
    <div className="flex justify-center my-4 space-x-4">
      <button
        onClick={() => setSelectedMenu('account')} 
        className="border-2 border-black text-black bg-white hover:bg-gray-100 font-bold py-2 px-4 rounded transition duration-150 ease-in-out"
      >
        🧑🏻‍💼 직원 계정 관리
      </button>
      <button
        onClick={() => setSelectedMenu('place')}
        className="border-2 border-black text-black bg-white hover:bg-gray-100 font-bold py-2 px-4 rounded transition duration-150 ease-in-out"  
      >
        🏢 현장명 관리
      </button>
    </div>
  );
};

export default AdminMenu;
