import React, { useState, useEffect } from 'react';
import { db } from '../../../Firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

const RecordUnit = ({ selectedPlace, selectedUnit, onUnitSelect }) => {
  const [units, setUnits] = useState([]);

  useEffect(() => {
    const fetchUnits = async () => {
      if (!selectedPlace) {
        setUnits([]);
        return;
      }

      const q = query(collection(db, "customerData"), where("place", "==", selectedPlace));
      const querySnapshot = await getDocs(q);
      let fetchedUnits = [];

      querySnapshot.forEach(doc => {
        const unitArray = doc.data().unit;
        if (unitArray && Array.isArray(unitArray)) {
          fetchedUnits = [...fetchedUnits, ...unitArray];
        }
      });

      setUnits([...new Set(fetchedUnits)]);
    };

    fetchUnits();
  }, [selectedPlace]);

  return (
    <div className="relative">
      <label htmlFor="unit-select" className="block text-sm font-medium text-gray-700">호기(필수)</label>
      <div className="inline-block w-full relative">
        <select
          id="unit-select"
          value={selectedUnit} // 이 부분을 추가하여 선택된 호기를 표시합니다.
          onChange={e => onUnitSelect(e.target.value)}
          className="appearance-none w-full p-2 border border-gray-300 rounded-md shadow-sm text-gray-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 pl-3 pr-10 py-2"
        >
          <option value="">호기를 선택하세요</option>
          {units.map((unit, index) => (
            <option key={index} value={unit}>{unit}</option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <svg className="fill-current h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
  );
};

export default RecordUnit;
