import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth'; // Custom hook to fetch auth state
import { db } from '../Firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import RecordWorkLog from '../components/workLogs/RecordWorkLog';
import FormConfirmModal from '../components/ui/modal/FormConfirmModal';

const RecordDataPage = () => {
  const currentUser = useAuth(); // Fetch current user using custom hook
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 최상단으로 이동
    window.scrollTo(0, 0);
  }, []);

  // Handle form submission
  const handleFormSubmit = (data) => {
    setFormData(data);
    setIsModalOpen(true);
  };

  // Handle actual saving of the work log
  const handleSave = async () => {
    if (!currentUser) {
      alert('로그인 정보가 없습니다. 다시 로그인해 주세요.');
      return;
    }
    
    try {
      await addDoc(collection(db, "newUnifiedData"), {
        ...formData,
        creatorName: currentUser.name || '알 수 없음', // Use name from currentUser
        creatorEmail: currentUser.email, // Use email from currentUser
        createdAt: serverTimestamp(), // Server timestamp for creation time
      });
      alert('작업 기록이 저장되었습니다.');
      setIsModalOpen(false);
      navigate('/work-logs'); // Navigate to work logs page after save
    } catch (error) {
      console.error("Error adding document: ", error);
      alert('작업 기록 저장 실패');
    }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center">
      <div className="py-5">
        <h4 className="text-gray-500 text-lg font-bold ml-48 mb-4 p-3 text-left cursor-pointer" onClick={() => navigate('/work-logs')}>
          🔙 작업일지 목록 보러가기
        </h4>
        <main className="flex flex-col items-center">
          <h2 className="text-2xl font-bold mb-4 text-center">✍🏻 작업일지 기록 📄</h2>
          <RecordWorkLog onSubmit={handleFormSubmit} />
          {isModalOpen && (
            <FormConfirmModal
              formData={formData}
              onClose={() => setIsModalOpen(false)}
              onSave={handleSave}
            />
          )}
        </main>
      </div>
    </div>
  );
};

export default RecordDataPage;

