import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import FindEmailPage from './pages/FindEmailPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import MyPage from './pages/MyPage';
import WorkLogPage from './pages/WorkLogPage';
import WorkDetailsPage from './pages/WorkDetailsPage';
import Header from './components/header/Header';
import AdminDashboard from './pages/AdminDashboard';
import RecordDataPage from './pages/RecordDataPage';
import UpdateWorkLog from './components/workLogs/UpdateWorkLog';
import ControllerButton from './components/controller/ControllerButton';
import DashboardPage from './components/dashboard/Dashboard';
import InventoryManagement from './components/workplace/InventoryManagement';
// import MigrateData from './components/workLogs/MigrateData';

const App = () => {
  return (
    <Router>
      <Header />
      <div className="pt-16">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/find-email" element={<FindEmailPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/mypage" element={<MyPage />} />
          <Route path="/work-logs" element={<WorkLogPage />} />
          <Route path="/work-logs/:workLogId" element={<WorkDetailsPage />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/record-work-log" element={<RecordDataPage />} />
          <Route path="/edit/:workLogId" element={<UpdateWorkLog />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/inventory-management" element={<InventoryManagement />} />
          {/* <Route path="/migrate" element={<MigrateData />} /> */}
        </Routes>
      </div>
      <ControllerButton />
    </Router>
  );
};

export default App;
