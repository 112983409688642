import React, { useState, useEffect } from 'react';
import { db } from '../../Firebase';
import { collection, getDocs, query, where, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import IsApprovedModal from '../ui/modal/IsApprovalModal'; // Modal 컴포넌트를 가져옵니다.

const UserApprovalList = () => {
  const [pendingUsers, setPendingUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({}); // 모달에 표시할 내용을 관리합니다.

  useEffect(() => {
    // 승인되지 않은 사용자만 조회
    const fetchPendingUsers = async () => {
      const q = query(collection(db, "users"), where("isApproved", "==", false));
      const querySnapshot = await getDocs(q);
      setPendingUsers(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchPendingUsers();
  }, []);

  const handleActionClick = (action, user) => {
    // 승인 또는 거절 버튼 클릭 시 모달을 표시하는 함수
    setModalContent({
      action,
      user,
      confirmAction: () => action === 'approve' ? approveUser(user.id) : rejectUser(user.id)
    });
    setIsModalOpen(true);
  };

  const approveUser = async (userId) => {
    await updateDoc(doc(db, "users", userId), { isApproved: true });
    setPendingUsers(pendingUsers.filter(user => user.id !== userId));
    setIsModalOpen(false);
  };

  const rejectUser = async (userId) => {
    await deleteDoc(doc(db, "users", userId));
    setPendingUsers(pendingUsers.filter(user => user.id !== userId));
    setIsModalOpen(false);
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-4">승인 대기 중인 사용자</h2>
      {pendingUsers.length > 0 ? (
        <ul>
          {pendingUsers.map((user) => (
            <li key={user.id} className="flex justify-between items-center mb-2 bg-white shadow p-4 rounded-lg">
              <span>{`${user.name} (${user.email})`}</span>
              <div>
                <button
                  onClick={() => handleActionClick('approve', user)}
                  className="text-white bg-green-500 hover:bg-green-700 font-bold py-2 px-4 rounded-l"
                >
                  승인
                </button>
                <button
                  onClick={() => handleActionClick('reject', user)}
                  className="text-white bg-red-500 hover:bg-red-700 font-bold py-2 px-4 rounded-r"
                >
                  거절
                </button>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>승인 대기 중인 사용자가 없습니다.</p>
      )}
      {isModalOpen && (
        <IsApprovedModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          content={(
            <>
              <p className="mb-4">{`정말로 '${modalContent.user.name}' 사용자를 ${modalContent.action === 'approve' ? '승인' : '거절'}하시겠습니까?`}</p>
              <div className="flex justify-end space-x-2">
                <button onClick={modalContent.confirmAction} className="py-2 px-4 bg-blue-500 hover:bg-blue-700 text-white rounded">
                  확인
                </button>
                <button onClick={() => setIsModalOpen(false)} className="py-2 px-4 bg-gray-500 hover:bg-gray-700 text-white rounded">
                  취소
                </button>
              </div>
            </>
          )}
        />
      )}
    </div>
  );
};

export default UserApprovalList;
