import React, { useState } from 'react';
import ImageModal from './modal/ImageModal';

const WorkDetailForm = ({ workLog }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const getWorkTypeGen = () => {
    if (!workLog) return ''; // workLog가 null이면 빈 문자열 반환
    switch (workLog.category) {
      case 'change': return '고장';
      case 'emergency': return '승객갇힘';
      case 'inspection': return '점검';
      case 'repair': return '수리공사';
      default: return '';
    }
  };

  // 이미지 클릭 핸들러
  const handleImageClick = (imageUrl) => {
    setCurrentImage(imageUrl);
    setShowModal(true);
  };

  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <table className="min-w-full">
        <tbody className="divide-y divide-gray-200">
          {/* 각 항목을 테이블 행으로 표시 */}
          <DetailItem label="작업분류" content={getWorkTypeGen()} />
          <DetailItem label="날짜" content={workLog.date} />
          <DetailItem label="작업자" content={workLog.creatorName} />
          <DetailItem label="동행 작업자" content={workLog.workers?.join(', ')} />
          <DetailItem label="현장명" content={workLog.place} />
          <DetailItem label="호기" content={workLog.unit} />
          {/* <DetailItem label="작업자" content={workLog.workers?.map(worker => worker.name).join(', ')} /> */}
          <DetailItem label="도착 시간" content={workLog.arrivalTime} />
          <DetailItem label="완료 시간" content={workLog.completionTime} />
          <DetailItem label="작업 내용" content={workLog.urgentContent} />
          {workLog.parts && workLog.parts.length > 0 && (
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-center align-middle font-semibold bg-gray-50">사용 부품</td>
              <td className="px-6 py-4 whitespace-nowrap">
                {workLog.parts.map((part, index) => (
                  <div key={index}>{`${part.name} / ${part.quantity}개`}</div>
                ))}
              </td>
            </tr>
          )}
          <DetailItem label="조치 사항" content={workLog.measures} />
          <DetailItem label="조치 결과" content={workLog.resultOfAction} />
          <DetailItem label="특이 사항" content={workLog.specialNotes} />
          <DetailItem label="작업일지 작성시간" content={workLog.createdAt?.toDate().toLocaleString()} />
          {workLog.images && workLog.images.length > 0 && (
          <tr>
            <td className="px-6 py-4 whitespace-nowrap text-center align-middle font-semibold bg-gray-50">현장 사진</td>
            <td className="px-6 py-4 whitespace-nowrap">
              <div className="flex flex-col space-y-4"> {/* 여기에서 flex 방향을 column으로 변경하고, 세로 간격을 추가 */}
                {workLog.images.map((image, index) => (
                  <img key={index} src={image.url} alt={`WorkLog Img ${index}`} className="w-32 h-32 object-cover rounded-lg" onClick={() => handleImageClick(image.url)} />
                ))}
              </div>
            </td>
          </tr>
        )}
        </tbody>
      </table>
      {showModal && (
          <ImageModal
            imageUrl={currentImage}
            onClose={() => setShowModal(false)}
          />
        )}
    </div>
  );
};

const DetailItem = ({ label, content }) => (
  <tr>
    <td className="px-6 py-4 whitespace-nowrap text-center align-middle font-semibold bg-gray-50">{label}</td>
    <td className="px-6 py-4 whitespace-nowrap">{content}</td>
  </tr>
);

export default WorkDetailForm;

