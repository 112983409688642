import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const WorkLogsMonthlyCount = () => {
  const [monthlyCounts, setMonthlyCounts] = useState({});

  useEffect(() => {
    const fetchWorkLogs = async () => {
      const snapshot = await getDocs(collection(db, "newUnifiedData"));
      const workLogs = snapshot.docs.map(doc => doc.data()).filter(log => log.date); // 날짜가 있는 데이터만 필터링

      const counts = workLogs.reduce((acc, log) => {
        const monthYear = log.date.slice(0, 7);
        if (!acc[monthYear]) {
          acc[monthYear] = 0;
        }
        acc[monthYear] += 1;
        return acc;
      }, {});

      const sortedKeys = Object.keys(counts).sort((a, b) => new Date(a) - new Date(b));
      const sortedCounts = sortedKeys.reduce((acc, key) => ({ ...acc, [key]: counts[key] }), {});

      setMonthlyCounts(sortedCounts);
    };

    fetchWorkLogs();
  }, []);

  const data = {
    labels: Object.keys(monthlyCounts).sort((a, b) => new Date(a) - new Date(b)),
    datasets: [
      {
        label: '월별 작업일지 수',
        data: Object.keys(monthlyCounts).sort((a, b) => new Date(a) - new Date(b)).map(key => monthlyCounts[key]),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
    ],
  };

  // 커스텀 플러그인을 정의합니다.
const customPlugin = {
  id: 'customPlugin',
  afterDatasetsDraw: function(chart) {
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          // 직접 폰트 스타일을 지정합니다.
          ctx.fillStyle = 'rgb(0, 0, 0)'; // 텍스트 색상
          const fontSize = 16; // 폰트 크기
          const fontStyle = 'normal'; // 폰트 스타일
          const fontFamily = 'Helvetica Neue'; // 폰트 패밀리
          ctx.font = `${fontStyle} ${fontSize}px ${fontFamily}`; // 폰트 스타일 직접 설정
          const dataString = dataset.data[index].toString();
          ctx.textAlign = 'center'; // 텍스트 정렬
          ctx.textBaseline = 'bottom'; // 텍스트 베이스라인
          const padding = 5; // 텍스트와 데이터 포인트 사이의 패딩
          const position = element.tooltipPosition();
          ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
        });
      }
    });
  }
};


const options = {
  plugins: {
    legend: {
      display: true,
    },
    tooltip: {
      enabled: true,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

  return (
    <div className="w-110 h-110 flex flex-col items-center justify-center">
      <h2 className="text-2xl font-bold mb-4 text-center">🗓 월별 작업일지 작성 수</h2>
      <Line data={data} options={options} plugins={[customPlugin]} />
    </div>
  );
};

export default WorkLogsMonthlyCount;
