import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../Firebase';
import RecordWorkLog from './RecordWorkLog';
import LoadingIndicator from '../ui/LoadingIndicator';

const UpdateWorkLog = () => {
  const { workLogId } = useParams();
  const [workLogData, setWorkLogData] = useState(null); // 초기 상태를 null로 설정하여 로드 상태 관리
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 관리
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWorkLog = async () => {
      setIsLoading(true); // 로딩 시작
      try {
        const docRef = doc(db, "newUnifiedData", workLogId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setWorkLogData(docSnap.data()); // 데이터 로드 성공
        } else {
          console.log("No such document!");
          navigate('/work-logs'); // 문서가 없으면 목록으로 리다이렉션
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
        navigate('/work-logs'); // 에러 발생 시 목록으로 리다이렉션
      } finally {
        setIsLoading(false); // 로딩 종료
      }
      
    };

    fetchWorkLog();
  }, [workLogId, navigate]);


  const handleFormSubmit = async (formData) => {
    setIsLoading(true); // 폼 제출 시 로딩 상태 활성화
    try {
      await updateDoc(doc(db, "newUnifiedData", workLogId), {
        ...formData,
        updatedAt: serverTimestamp(),
      });
      alert('작업일지가 성공적으로 업데이트되었습니다.');
      navigate('/work-logs'); // 업데이트 성공 후 목록으로 리다이렉션
    } catch (error) {
      console.error("Error updating document: ", error);
      alert('작업일지 업데이트 실패');
    } finally {
      setIsLoading(false); // 로딩 종료
    }
  };

  const handleBack = () => {
    navigate(-1); // 뒤로 가기
  };

  if (isLoading || !workLogData) {
    return <LoadingIndicator />; // 로딩 중이거나 데이터가 없을 때 로딩 인디케이터 표시
  }

  return (
    <div>
      <button onClick={handleBack} className="text-gray-500 text-lg font-bold ml-4 mb-4 mt-4 p-3 text-left cursor-pointer">🔙 뒤로 가기</button>
      <div className="flex items-center flex-col mt-6">
        <h2 className="text-2xl font-bold mb-4 text-center">⌨️  작업일지 수정  ⌨️</h2>
        {workLogData && <RecordWorkLog initialFormData={workLogData} onSubmit={handleFormSubmit} />}
      </div>
    </div>
  );
};

export default UpdateWorkLog;
