import React from 'react';

const RecordCompletionTime = ({ completionTime, onTimeChange }) => {
  return (
    <div className="w-1/2 px-2">
      <label htmlFor="completion-time" className="block text-sm font-medium text-gray-700">
        완료 시각
      </label>
      <input
        type="time"
        id="completion-time"
        name="completionTime"
        value={completionTime}
        onChange={onTimeChange}
        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
      />
    </div>
  );
};

export default RecordCompletionTime;
