// src/pages/FindEmailPage.js
import React, { useState } from 'react';
import { functions } from '../Firebase';
import { httpsCallable } from 'firebase/functions';
import { Link } from 'react-router-dom';

const FindEmailPage = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [emailResult, setEmailResult] = useState('');
  const [loading, setLoading] = useState(false);
  const findEmailFunction = httpsCallable(functions, 'findUserEmail');

  const findEmail = async () => {
    setLoading(true);
    try {
      const result = await findEmailFunction({ name, phoneNumber });
      if (result.data.emails && result.data.emails.length > 0) {
        setEmailResult(result.data.emails.join(", ")); // 이메일 배열이 있는 경우에만 join 실행
      } else {
        setEmailResult('일치하는 이메일이 없습니다.');
      }
    } catch (error) {
      setEmailResult('일치하는 사용자 정보가 없습니다.');
      console.error('Error finding email:', error);
    }
    setLoading(false);
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-800">
      <div className="p-6 bg-white rounded-lg shadow-xl max-w-md w-full mx-4">
        <h2 className="text-2xl font-bold mb-10 text-center">아이디 찾기</h2>

        <div className="space-y-6">
          <input
            type="text"
            placeholder="이름"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <input
            type="tel"
            placeholder="핸드폰 번호"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
          <button onClick={findEmail} className={`btn w-full p-2 rounded-md text-white ${name && phoneNumber ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-500'}`}>
            이메일 찾기
          </button>
        </div>

        {loading ? <p>조회 중...</p> : emailResult && (
          <div className="mt-4 p-4 bg-gray-100 rounded-md">
            <p className="text-gray-700">{emailResult}</p>
          </div>
        )}

        <div className="flex justify-between text-sm text-blue-500 mt-6">
            <Link to="/find-email">아이디 찾기</Link>
            <Link to="/reset-password">비밀번호 찾기</Link>
            <Link to="/signup">회원가입</Link> {/* 수정된 부분 */}
          </div>
      </div>
    </div>
  );
};

export default FindEmailPage;
