// DeleteInvenModal.js
import React from 'react';

const DeleteInvenModal = ({ onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-4 rounded-lg shadow-xl">
        <h2 className="text-lg font-medium leading-6 text-gray-900">재고 항목 삭제</h2>
        <div className="mt-2">
          <p className="text-sm text-gray-500">이 재고 항목을 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.</p>
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={onConfirm}
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mr-2"
          >
            삭제
          </button>
          <button
            onClick={onClose}
            className="inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteInvenModal;
