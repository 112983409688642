// src/components/ui/modal/PlaceAddModal.js
import React, { useState } from 'react';
import { db } from '../../../Firebase';
import { collection, addDoc } from 'firebase/firestore';

const PlaceAddModal = ({ isOpen, onClose, onAddData }) => {
  const [newData, setNewData] = useState({ 
    place: '', 
    address: '', 
    unit: [] // 배열로 초기화
  });

  const handleUnitChange = (e) => {
    // 쉼표로 구분된 문자열을 배열로 변환
    const unitArray = e.target.value.split(',').map(item => item.trim()).filter(item => item !== '');
    setNewData({ ...newData, unit: unitArray });
  };

  const addNewData = async () => {
    if (!newData.place || !newData.address) {
      alert("현장명과 주소를 입력해주세요.");
      return;
    }

    try {
      // unit이 비어있는 경우에도 빈 배열로 저장
      const dataToSave = {
        ...newData,
        unit: newData.unit.length > 0 ? newData.unit : []
      };
      
      // Firestore에 데이터 추가
      const docRef = await addDoc(collection(db, "customerData"), dataToSave);
      
      // 새로 추가된 데이터를 부모 컴포넌트에 전달
      onAddData({ id: docRef.id, ...dataToSave });
      
      // 모달 닫기 및 폼 초기화
      onClose();
      setNewData({ place: '', address: '', unit: [] });
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("데이터 저장 중 오류가 발생했습니다.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">새 현장 데이터 추가</h3>
          <div className="mt-2 mb-4">
            <input
              className="mt-2 px-3 py-3 border border-gray-300 rounded-md w-full"
              value={newData.place}
              onChange={(e) => setNewData({ ...newData, place: e.target.value })}
              placeholder="현장명"
            />
            <input
              className="mt-2 px-3 py-3 border border-gray-300 rounded-md w-full"
              value={newData.address}
              onChange={(e) => setNewData({ ...newData, address: e.target.value })}
              placeholder="주소"
            />
            <input
              className="mt-2 px-3 py-3 border border-gray-300 rounded-md w-full"
              value={newData.unit.join(', ')}
              onChange={handleUnitChange}
              placeholder="호기"
            />
            <div className="mt-4 text-sm text-gray-600">여러 개의 호기를 입력할 때는 쉼표(,)를 사용하세요.</div>
            <div className="mt-2 text-sm text-gray-600">데이터를 동시에 입력 할 수 있습니다.</div>
            <div className="mt-2 text-sm text-gray-600">(예) 101-1, 101-2, 102-1, 102-2</div>
            <div className="items-center px-4 py-3">
              <button
                onClick={addNewData}
                className="px-4 py-2 mb-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                저장
              </button>
              <button
                onClick={onClose}
                className="px-4 py-2 bg-gray-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                취소
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceAddModal;
