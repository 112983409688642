import React from 'react';

const InventoryItem = ({ item, handleInputChange, handleSaveItem, handleEditItem, handleShowDeleteModal, handleUsageHistory }) => {
  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {item.isEditing ? (
          <input
            type="text"
            value={item.name}
            onChange={(e) => handleInputChange(item.id, 'name', e.target.value)}
            className="border px-2 py-1 rounded w-full"
            placeholder="부품명 입력"
          />
        ) : (
          item.name
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {item.isEditing ? (
          <input
            type="number"
            value={item.stock}
            onChange={(e) => handleInputChange(item.id, 'stock', e.target.value)}
            className="border px-2 py-1 rounded w-full"
            placeholder="재고량 입력"
          />
        ) : (
          item.stock
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {item.isEditing ? (
          <input
            type="text"
            value={item.memo}
            onChange={(e) => handleInputChange(item.id, 'memo', e.target.value)}
            className="border px-2 py-1 rounded w-full"
            placeholder="메모 (선택사항)"
          />
        ) : (
          item.memo || ''
        )}
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
        <div className="flex flex-col space-y-2">
          {item.isEditing ? (
            <>
              <button
                onClick={() => handleSaveItem(item.id)}
                className="text-white bg-green-500 hover:bg-green-700 font-medium rounded-lg text-sm px-4 py-2"
              >
                저장
              </button>
              <button
                onClick={() => handleShowDeleteModal(item.id)}
                className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2"
              >
                삭제
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => handleUsageHistory(item.name)}
                className="text-white bg-yellow-500 hover:bg-yellow-700 font-medium rounded-lg text-sm px-4 py-2"
              >
                사용내역
              </button>
              <button
                onClick={() => handleEditItem(item.id)}
                className="text-white bg-blue-500 hover:bg-blue-700 font-medium rounded-lg text-sm px-4 py-2"
              >
                수정
              </button>
              <button
                onClick={() => handleShowDeleteModal(item.id)}
                className="text-white bg-red-500 hover:bg-red-700 font-medium rounded-lg text-sm px-4 py-2"
              >
                삭제
              </button>
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default InventoryItem;
