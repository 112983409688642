import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../Firebase';
import { doc, getDoc, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import html2canvas from 'html2canvas';
import useAuth from '../hooks/useAuth'; // 가정한 useAuth 훅
import DeleteConfirmModal from '../components/ui/modal/DeleteConfirmModal';
import LoadingIndicator from '../components/ui/LoadingIndicator';
import WorkDetailForm from '../components/ui/WorkDetailForm';
import PrintButton from '../components/ui/PrintButton';
import ImageSaveButton from '../components/ui/ImageSaveButton';
// import WorkLogShareButton from '../components/ui/WorkLogShareButton';
import SameWorkLog from '../components/workLogs/SameWorkLog';


const WorkDetailsPage = () => {
  const { workLogId } = useParams();
  const [workLog, setWorkLog] = useState(null);
  const [relatedWorkLogs, setRelatedWorkLogs] = useState([]); // 관련된 작업일지를 저장할 상태
  const [isLoading, setIsLoading] = useState(true);
  const currentUser = useAuth(); // 현재 로그인한 사용자 정보를 가져오는 훅
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const navigate = useNavigate();
  const workDetailFormRef = useRef(null); // WorkDetailForm을 위한 ref 생성

  const saveAsImage = () => {
    if (workDetailFormRef.current) {
      html2canvas(workDetailFormRef.current).then((canvas) => {
        const image = canvas.toDataURL('image/png', 1.0);

        // 파일명 생성 로직
        const fileName = `${workLog.place}_${workLog.unit}_${workLog.creatorName}_${workLog.date.replaceAll('/', '-')}.png`;

        const link = document.createElement('a');
        link.href = image;
        link.download = fileName; // 저장될 이미지 파일명
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  };

  const fetchRelatedWorkLogs = useCallback(async (place, unit) => {
    const q = query(collection(db, "newUnifiedData"), where("place", "==", place), where("unit", "==", unit));
    const querySnapshot = await getDocs(q);
    const logs = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setRelatedWorkLogs(logs.filter(log => log.id !== workLogId)); // 현재 보고 있는 로그를 제외하고 설정합니다.
  }, [workLogId]); // useCallback을 사용하여 의존성 명시

  useEffect(() => {
    // 컴포넌트가 마운트될 때 스크롤을 최상단으로 이동
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchWorkLog = async () => {
      setIsLoading(true);
      const docRef = doc(db, "newUnifiedData", workLogId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setWorkLog(data);
        fetchRelatedWorkLogs(data.place, data.unit); // 여기서 fetchRelatedWorkLogs 함수를 호출합니다.
      } else {
        console.log("No such document!");
      }
      setIsLoading(false);
    };

    fetchWorkLog();
  }, [workLogId, fetchRelatedWorkLogs]); // 의존성 배열에 fetchRelatedWorkLogs 추가

  // 관리자 이메일 주소
  // const ADMIN_EMAILS = "admin@admin.com";
  // 관리자 이메일 주소 리스트
  const ADMIN_EMAILS = ["admin@admin.com", "quietjean@naver.com"];

  // 현재 로그인한 사용자가 관리자이거나, 작업일지의 작성자인지 확인
  const canEditOrDelete = currentUser && (ADMIN_EMAILS.includes(currentUser.email) || currentUser.name === workLog.creatorName);

  // 삭제 확인 모달을 표시하는 함수
  const handleDeleteClick = () => {
    setShowDeleteConfirm(true);
  };

  // 실제 삭제 로직을 수행하는 함수
  const handleConfirmDelete = async () => {
    await deleteDoc(doc(db, "newUnifiedData", workLogId));
    setShowDeleteConfirm(false);
    navigate('/work-logs');
  };

  // 작업일지 수정 페이지로 이동하는 함수
  const handleEditClick = () => {
    navigate(`/edit/${workLogId}`);
  };

   // workType을 직접 계산하는 대신, workLog가 유효할 때만 workType을 계산하는 함수를 만듭니다.
  const getWorkType = () => {
    if (!workLog) return ''; // workLog가 null이면 빈 문자열 반환
    switch (workLog.category) {
      case 'change': return '💥 고장';
      case 'emergency': return '🚨 승객갇힘';
      case 'inspection': return '🔍 점검';
      case 'repair': return '🛠 수리공사';
      default: return '';
    }
  };

  const handleTitleClick = () => {
    navigate('/work-logs'); // 홈페이지 또는 WorkLogPage.js의 첫 화면으로 이동하는 경로
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="max-w-4xl mx-auto p-4 bg-white rounded-lg shadow-md">
      <h4
        className="text-lg font-bold mb-4 p-3 text-left cursor-pointer"
        onClick={handleTitleClick}
      >
        🔙 작업일지 목록
      </h4>
      <div className="flex justify-between items-center mb-4">
        <div className="text-2xl font-bold text-center flex-grow">
          <div>{getWorkType()}</div>
          <div>{workLog.place} {workLog.unit}</div>
        </div>
      </div>
      <div className="flex justify-center items-center space-x-6 mt-6 mb-10">
        <PrintButton />
        <ImageSaveButton onSaveAsImage={saveAsImage} />
        {/* <WorkLogShareButton /> */}
      </div>

      <div ref={workDetailFormRef}> {/* WorkDetailForm 컴포넌트를 감싸는 div에 ref 설정 */}
        <WorkDetailForm workLog={workLog} />
      </div>

      {canEditOrDelete && (
        <div className="flex justify-center space-x-4 my-4 mb-14">
          <button className="border-red-500 text-red-500 border-2 px-4 py-2 rounded cursor-pointer hover:bg-red-500 hover:text-white transition-colors" onClick={handleDeleteClick}>작업일지 삭제</button>
          <button className="border-green-500 text-green-500 border-2 px-4 py-2 rounded cursor-pointer hover:bg-green-500 hover:text-white transition-colors" onClick={handleEditClick}>수정</button>
        </div>
      )}
      {showDeleteConfirm && (
        <DeleteConfirmModal
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={handleConfirmDelete}
        />
      )}
      <SameWorkLog relatedWorkLogs={relatedWorkLogs} currentWorkLogId={workLogId} />
    </div>
  );
};

export default WorkDetailsPage;
