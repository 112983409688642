import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase'; // Firestore 인스턴스 경로에 맞게 조정해주세요.

const UserMonthWorkRecord = () => {
  const [monthWorkCount, setMonthWorkCount] = useState(0);

  useEffect(() => {
    const fetchMonthWorkRecords = async () => {
      const today = new Date();
      const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()).toISOString().split('T')[0];
      const todayFormatted = today.toISOString().split('T')[0];

      const workRecordsQuery = query(
        collection(db, "newUnifiedData"),
        where("date", ">=", lastMonth),
        where("date", "<=", todayFormatted)
      );

      const querySnapshot = await getDocs(workRecordsQuery);
      setMonthWorkCount(querySnapshot.docs.length);
    };

    fetchMonthWorkRecords();
  }, []);

  return (
    <div className="flex justify-center mt-10">
      <div className="bg-gradient-to-br from-yellow-400 to-red-500 text-white text-center p-6 rounded-lg shadow-xl" style={{ width: '300px', height: '300px' }}>
        <h2 className="text-xl font-bold mb-4">최근 30일 작업일지 작성수</h2>
        <div className="text-6xl font-bold mb-6">
          {monthWorkCount}
        </div>
        <p className="text-lg">
          최근 30일 간 완료된 작업의 수입니다. 지속적인 관리와 확인을 통해 서비스의 질을 높일 수 있습니다. 
        </p>
      </div>
    </div>
  );
};

export default UserMonthWorkRecord;
